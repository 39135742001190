var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { BsFilter } from 'react-icons/all';
import { useQuery } from 'react-query';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { ImpossibilidadeResposta } from '@app/modules/routine-checklist/impossibilidade-resposta';
import { FlexRow } from '@app/modules/routine-checklist/quick-checklist/styles';
import { ActivityIndicator, Col, FlashMessageEnum, Row } from '@atomic';
import { ChecklistCard } from '../checklist-card';
import { ChecklistFilterModal } from '../checklist-filter-modal/checklist-filter-modal.component';
import { DateRangeModal } from '../date-range-modal/date-range-modal.component';
import { LoadMoreInfo, ChecklistButton } from './checklists-list.styled';
export var ChecklistsPagination = function (_a) {
    var title = _a.title, type = _a.type, url = _a.url, pagination = _a.pagination, showFilters = _a.showFilters;
    var showFlashMessage = useFlashMessage()[0];
    var _b = useState(false), isLoading = _b[0], setLoading = _b[1];
    var _c = useState(false), isLoadingMore = _c[0], setLoadingMore = _c[1];
    var _d = useState(false), isModalDateRangeOpen = _d[0], setModalDateRangeOpen = _d[1];
    var _e = useState(false), isModalFilterOpen = _e[0], setModalFilterOpen = _e[1];
    var _f = useState([]), checklists = _f[0], setChecklists = _f[1];
    var _g = useState(4), defaultCardQuantity = _g[0], setDefaultCardQuantity = _g[1];
    var _h = useState(type), checkType = _h[0], setCheckType = _h[1];
    var _j = useState(url), baseUrl = _j[0], setBaseUrl = _j[1];
    var _k = useState(pagination), paginationData = _k[0], setPaginationData = _k[1];
    var _l = useState(undefined), startDateFilter = _l[0], setStartDateFilter = _l[1];
    var _m = useState(undefined), endDateFilter = _m[0], setEndDateFilter = _m[1];
    var _o = useState([]), unidadesFilter = _o[0], setUnidadesFilter = _o[1];
    var _p = useState([]), checklistUnidadeTypesFilter = _p[0], setChecklistUnidadeTypesFilter = _p[1];
    var _q = useState([]), gestorFilter = _q[0], setGestorFilter = _q[1];
    var loadMore = function () { return __awaiter(void 0, void 0, void 0, function () {
        var pageNumber, pageSize, unidadeIds, types, gestorIds, params, resp, data;
        var _a, _b, _c, _d, _e, _f, _g, _h;
        return __generator(this, function (_j) {
            switch (_j.label) {
                case 0:
                    setLoadingMore(true);
                    pageNumber = ((_a = paginationData === null || paginationData === void 0 ? void 0 : paginationData.pageNumber) !== null && _a !== void 0 ? _a : 0) + 1;
                    pageSize = (_b = paginationData === null || paginationData === void 0 ? void 0 : paginationData.pageSize) !== null && _b !== void 0 ? _b : 10;
                    unidadeIds = (_c = unidadesFilter === null || unidadesFilter === void 0 ? void 0 : unidadesFilter.map(function (item) { return item.value; })) !== null && _c !== void 0 ? _c : [];
                    types = (_d = checklistUnidadeTypesFilter === null || checklistUnidadeTypesFilter === void 0 ? void 0 : checklistUnidadeTypesFilter.map(function (item) { return item.type; })) !== null && _d !== void 0 ? _d : [];
                    gestorIds = (_e = gestorFilter === null || gestorFilter === void 0 ? void 0 : gestorFilter.map(function (item) { return item.value; })) !== null && _e !== void 0 ? _e : [];
                    params = buildQueryParams(pageNumber, pageSize, startDateFilter, endDateFilter, unidadeIds, types, gestorIds);
                    return [4 /*yield*/, axiosClient.get(baseUrl, { params: params })];
                case 1:
                    resp = _j.sent();
                    data = (_f = resp.data) === null || _f === void 0 ? void 0 : _f.data;
                    (_g = data === null || data === void 0 ? void 0 : data.results) === null || _g === void 0 ? void 0 : _g.forEach(function (item) { return (item.type = checkType); });
                    setChecklists(__spreadArrays(checklists, ((_h = data === null || data === void 0 ? void 0 : data.results) !== null && _h !== void 0 ? _h : [])));
                    setPaginationData(data);
                    setLoadingMore(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var reload = function (startDate, endDate, unidadeIds, types, gestorIds) { return __awaiter(void 0, void 0, void 0, function () {
        var pageNumber, pageSize, params, resp, data;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setLoading(true);
                    setChecklists([]);
                    pageNumber = 1;
                    pageSize = 10;
                    params = buildQueryParams(pageNumber, pageSize, startDate, endDate, unidadeIds, types, gestorIds);
                    return [4 /*yield*/, axiosClient.get(baseUrl, { params: params })];
                case 1:
                    resp = _d.sent();
                    data = (_a = resp.data) === null || _a === void 0 ? void 0 : _a.data;
                    (_b = data === null || data === void 0 ? void 0 : data.results) === null || _b === void 0 ? void 0 : _b.forEach(function (item) { return (item.type = checkType); });
                    setChecklists((_c = data === null || data === void 0 ? void 0 : data.results) !== null && _c !== void 0 ? _c : []);
                    setPaginationData(data);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var _r = useQuery(['get-checklists', type], function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp, data, err_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 2, , 3]);
                    setLoading(true);
                    return [4 /*yield*/, axiosClient.get(baseUrl)];
                case 1:
                    resp = _d.sent();
                    data = (_a = resp.data) === null || _a === void 0 ? void 0 : _a.data;
                    (_b = data === null || data === void 0 ? void 0 : data.results) === null || _b === void 0 ? void 0 : _b.forEach(function (item) { return (item.type = checkType); });
                    setChecklists((_c = data === null || data === void 0 ? void 0 : data.results) !== null && _c !== void 0 ? _c : []);
                    setPaginationData(data);
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _d.sent();
                    showFlashMessage(FlashMessageEnum.alert, 'Erro ao carregar Histórico de Checklists');
                    setLoading(false);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: true,
    }), isFetching = _r.isFetching, refetch = _r.refetch;
    var handleCardQuantityRender = function (height) {
        if (height < 400) {
            setDefaultCardQuantity(1);
        }
        else if (height < 500) {
            setDefaultCardQuantity(2);
        }
        else if (height < 650) {
            setDefaultCardQuantity(2);
        }
        else if (height < 780) {
            setDefaultCardQuantity(3);
        }
        else if (height < 820) {
            setDefaultCardQuantity(4);
        }
        else {
            setDefaultCardQuantity(5);
        }
    };
    useEffect(function () {
        handleCardQuantityRender(window.innerHeight);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.innerHeight]);
    var _s = useState(false), openImpossibilita = _s[0], setOpenImpossibilita = _s[1];
    var _t = useState(false), impossibilitaChecklist = _t[0], setImpossibilitaChecklist = _t[1];
    var handleImpossibilitarRespostaChecklist = function (e, checklist) {
        e.stopPropagation();
        setImpossibilitaChecklist(checklist);
        setOpenImpossibilita(true);
    };
    var handleCloseDateRangeModal = function (startDate, endDate) {
        var _a, _b;
        setModalDateRangeOpen(false);
        setStartDateFilter(startDate);
        setEndDateFilter(endDate);
        var unidadeIds = (_a = unidadesFilter.map(function (item) { return item.value; })) !== null && _a !== void 0 ? _a : [];
        var types = (_b = checklistUnidadeTypesFilter.map(function (item) { return item.type; })) !== null && _b !== void 0 ? _b : [];
        reload(startDate, endDate, unidadeIds, types);
    };
    var handleCloseFilterModal = function (unidades, checklistUnidadeTypes, gestorList) {
        var _a, _b, _c;
        setModalFilterOpen(false);
        setUnidadesFilter(unidades !== null && unidades !== void 0 ? unidades : []);
        setChecklistUnidadeTypesFilter(checklistUnidadeTypes !== null && checklistUnidadeTypes !== void 0 ? checklistUnidadeTypes : []);
        setGestorFilter(gestorList !== null && gestorList !== void 0 ? gestorList : []);
        var unidadeIds = (_a = unidades.map(function (item) { return item.value; })) !== null && _a !== void 0 ? _a : [];
        var types = (_b = checklistUnidadeTypes.map(function (item) { return item.type; })) !== null && _b !== void 0 ? _b : [];
        var gestorIds = (_c = gestorList.map(function (item) { return item.value; })) !== null && _c !== void 0 ? _c : [];
        reload(startDateFilter, endDateFilter, unidadeIds, types, gestorIds);
    };
    var buildQueryParams = function (pageNumber, pageSize, startDate, endDate, unidades, types, gestorIds) {
        var _a, _b, _c;
        return {
            pageNumber: pageNumber,
            pageSize: pageSize,
            startDate: startDate ? moment(startDate).local().format('YYYY-MM-DD') : null,
            endDate: endDate ? moment(endDate).local().format('YYYY-MM-DD') : null,
            unidadeEmpresaIds: (_a = unidades === null || unidades === void 0 ? void 0 : unidades.join(',')) !== null && _a !== void 0 ? _a : null,
            types: (_b = types === null || types === void 0 ? void 0 : types.join(',')) !== null && _b !== void 0 ? _b : null,
            gestorIds: (_c = gestorIds === null || gestorIds === void 0 ? void 0 : gestorIds.join(',')) !== null && _c !== void 0 ? _c : null,
        };
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ImpossibilidadeResposta, { open: openImpossibilita, onClose: function () { return setOpenImpossibilita(false); }, checklist: impossibilitaChecklist, checklistImpossibilitado: refetch }),
        React.createElement(DateRangeModal, { isOpened: isModalDateRangeOpen, handleOnClose: handleCloseDateRangeModal, startDateSelected: startDateFilter, endDateSelected: endDateFilter }),
        React.createElement(ChecklistFilterModal, { isOpened: isModalFilterOpen, handleOnClose: handleCloseFilterModal, unidadesSelected: unidadesFilter !== null && unidadesFilter !== void 0 ? unidadesFilter : [], checklistTypesSelected: checklistUnidadeTypesFilter !== null && checklistUnidadeTypesFilter !== void 0 ? checklistUnidadeTypesFilter : [], gestorSelected: gestorFilter !== null && gestorFilter !== void 0 ? gestorFilter : [] }),
        showFilters && (React.createElement(Row, { style: { marginTop: '20px' } },
            React.createElement(Col, { xs: 6 },
                React.createElement(ChecklistButton, { style: { maxWidth: '140px' }, onClick: function () { return setModalFilterOpen(function (old) { return !old; }); }, variant: "primary", expanded: true },
                    React.createElement(BsFilter, { fontSize: 20 }),
                    React.createElement("span", null, "Filtrar"))),
            React.createElement(Col, { xs: 6, style: { display: 'flex', justifyContent: 'end' } },
                React.createElement(ChecklistButton, { style: { maxWidth: '180px' }, onClick: function () { return setModalDateRangeOpen(function (old) { return !old; }); }, variant: "primary", expanded: true },
                    React.createElement("span", null, "Selecionar Datas"))))),
        React.createElement("div", { style: { marginTop: '25px' } }, checklists === null || checklists === void 0 ? void 0 : checklists.map(function (checklist, idx) { return (React.createElement(ChecklistCard, { key: "" + checklist.id + idx, checklist: checklist, isFirst: idx === 0, isLast: idx === checklists.length - 1, type: checklist.type, onClickImpossibilitar: handleImpossibilitarRespostaChecklist })); })),
        !isLoading && !isLoadingMore && (paginationData === null || paginationData === void 0 ? void 0 : paginationData.pageNumber) < (paginationData === null || paginationData === void 0 ? void 0 : paginationData.totalPages) && (React.createElement(LoadMoreInfo, { onClick: function () { return loadMore(); }, style: { padding: '10px' } }, 'Ver mais')),
        isLoadingMore && (paginationData === null || paginationData === void 0 ? void 0 : paginationData.pageNumber) < (paginationData === null || paginationData === void 0 ? void 0 : paginationData.totalPages) && (React.createElement(FlexRow, { style: { width: '100%', alignItems: 'center', justifyContent: 'center', padding: '10px' } },
            React.createElement(ActivityIndicator, { type: "spinner", size: "1x" }))),
        isLoading || isFetching ? (React.createElement(FlexRow, { style: { width: '100%', alignItems: 'center', justifyContent: 'center' } },
            React.createElement(ActivityIndicator, { type: "spinner", size: "2x" }))) : null));
};
