import React from 'react';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { HomeOption } from '@app/modules/map/map.page.style';
import menu from '@assets/img/tutorial/menu.png';
import { StepContainer, BoldParagraph, CentralizedRow } from '../tutorial.styles';
var QuintoStep = function () { return (React.createElement(StepContainer, null,
    React.createElement(BoldParagraph, null, "Como navegar na aplica\u00E7\u00E3o"),
    React.createElement("p", null, "Esse bot\u00E3o sempre estar\u00E1 presente no topo direito da P\u00E1gina Inicial."),
    React.createElement(CentralizedRow, null,
        React.createElement(HomeOption, null,
            React.createElement(PersonOutlineOutlinedIcon, { sx: { fill: '#fff', fontSize: '1.8rem' } }))),
    React.createElement("p", null, "Nele voc\u00EA ter\u00E1 acesso ao seu Perfil e as seguintes configura\u00E7\u00F5es abaixo:"),
    React.createElement("img", { alt: "", src: menu, width: "70%" }))); };
export default QuintoStep;
