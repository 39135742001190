var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
var _a;
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from 'react';
import { useStore } from 'react-context-hook';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { HeaderV2 } from '@app/components/mol.header/header.component-v2';
import { BottomNav } from '@app/components/mol.track-bottom-nav';
import { ListItemComponent } from '@app/components/obj.list-item';
import { axiosClient } from '@app/configure-app';
import { OuterDiv } from '@app/modules/questionnarie/questionnaire.styled';
var FilterTypeCommunicate;
(function (FilterTypeCommunicate) {
    FilterTypeCommunicate[FilterTypeCommunicate["COMMUNICATE"] = 0] = "COMMUNICATE";
    FilterTypeCommunicate[FilterTypeCommunicate["COMMUNICATE_NEW"] = 1] = "COMMUNICATE_NEW";
    FilterTypeCommunicate[FilterTypeCommunicate["COMMUNICATE_READ"] = 2] = "COMMUNICATE_READ";
    FilterTypeCommunicate[FilterTypeCommunicate["COMMUNICATE_UNANSWERED"] = 3] = "COMMUNICATE_UNANSWERED";
})(FilterTypeCommunicate || (FilterTypeCommunicate = {}));
var FilterLabels = (_a = {},
    _a[FilterTypeCommunicate.COMMUNICATE] = 'Lista de Comunicados',
    _a[FilterTypeCommunicate.COMMUNICATE_NEW] = 'Comunicados Novos',
    _a[FilterTypeCommunicate.COMMUNICATE_READ] = 'Comunicados Concluídos',
    _a[FilterTypeCommunicate.COMMUNICATE_UNANSWERED] = 'Comunicados Não Respondidos',
    _a);
export var Communicates = function () {
    var history = useHistory();
    var company = useStore('company')[0];
    var _a = React.useState([]), communicates = _a[0], setCommunicates = _a[1];
    var isLoading = useQuery('get-categorias-comunicados', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, err_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get('comunicado-empresa')];
                case 1:
                    response = _c.sent();
                    setCommunicates((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data);
                    return [2 /*return*/, (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data];
                case 2:
                    err_1 = _c.sent();
                    console.error(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { refetchOnMount: true }).isLoading;
    var handleClickCommunicate = function (id) {
        history.push("/quiz-comunicado/" + id);
    };
    var getStatusCommunicateLabel = function (communicate) {
        if (!communicate.respondido && communicate.lido) {
            return {
                filterType: FilterTypeCommunicate.COMMUNICATE_UNANSWERED,
                label: 'Pendente',
                color: '#C72000',
            };
        }
        if (communicate.lido) {
            return {
                filterType: FilterTypeCommunicate.COMMUNICATE_READ,
                label: 'Concluído',
                color: '#42A4FF',
            };
        }
        return {
            filterType: FilterTypeCommunicate.COMMUNICATE_NEW,
            label: 'Novo',
            color: '#008425',
        };
    };
    var buildCommunicatesObject = function (communicatesData) {
        if (!communicatesData) {
            return [];
        }
        return communicatesData.map(function (communicate) {
            var statusCommunicate = getStatusCommunicateLabel(communicate);
            return {
                title: communicate.titulo,
                filterType: statusCommunicate.filterType,
                status: {
                    categoryLabel: communicate.categoria.nome,
                    statusLabel: statusCommunicate.label,
                    statusLabelColor: statusCommunicate.color,
                },
                onClick: function () { return handleClickCommunicate(communicate.id); },
            };
        });
    };
    var getCategoryFilters = function (communicatesData) {
        if (!communicatesData) {
            return [];
        }
        return __spreadArrays([
            'Filtro de Categoria'
        ], communicatesData
            .map(function (communicate) { return communicate.categoria.nome; })
            .filter(function (value, index, self) { return self.indexOf(value) === index; }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(OuterDiv, null,
            React.createElement(HeaderV2, { withBackButton: true, companyLogoURL: company === null || company === void 0 ? void 0 : company.imgLogoEscuro, backButtonFn: function () { return history.push('/'); } }),
            React.createElement(ListItemComponent, { title: "Comunicados", filters: {
                    filtersLabels: FilterLabels,
                    defaultFilter: FilterTypeCommunicate.COMMUNICATE,
                }, categoryFilters: getCategoryFilters(communicates), items: buildCommunicatesObject(communicates) })),
        React.createElement(BottomNav, null)));
};
