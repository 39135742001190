var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import * as React from 'react';
import { subDays } from 'date-fns';
import { darken } from 'polished';
import { useStore } from 'react-context-hook';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { useTheme } from 'styled-components';
import Container from 'typedi';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AuthStorageService } from '@app/data/storage/auth.storage';
import { AppPath } from '@app/routes';
import { ActivityIndicator, Body, Button, Col, FaIcon, FlashMessageEnum, Hbox, Row, Separator } from '@atomic';
import { RankingTableItem } from '../ranking-table-item/ranking-table-item.component';
import { Filter, FilterContainerStyled, FilterOptionsStyled, FiltersGroup, Ranking, } from './ranking-table.components.styled';
var GET_RANKING_URL = 'ranking';
var GET_COURSES_URL = 'ranking/cursos';
var GET_SHARED_COURSES_URL = 'curso/empresa/instalados';
var GET_GROUPS_URL = 'filial';
var GET_UNIDADES_URL = 'unidade-empresa/por-usuario';
var FilterType;
(function (FilterType) {
    FilterType["General"] = "General";
    FilterType["Weekly"] = "Weekly";
    FilterType["Course"] = "Course";
    FilterType["Group"] = "Group";
    FilterType["Unit"] = "Unit";
})(FilterType || (FilterType = {}));
var getQueryParams = (_a = {},
    _a[FilterType.Course] = function (courseId) { return ({
        CursoId: courseId,
    }); },
    _a[FilterType.Group] = function (groupId) { return ({
        FilialId: groupId,
    }); },
    _a[FilterType.Unit] = function (unidadeId) { return ({
        UnidadeId: unidadeId,
    }); },
    _a[FilterType.Weekly] = { DataInicio: subDays(new Date(), 6), DataFim: new Date() },
    _a[FilterType.General] = {},
    _a);
export var RankingTable = function (_a) {
    var _b, _c, _d, _e, _f;
    var data = _a.data, tarefa = _a.tarefa, _g = _a.ocultarBotaoConcluir, ocultarBotaoConcluir = _g === void 0 ? false : _g, isProfile = _a.isProfile;
    var theme = useTheme();
    var showFlashMessage = useFlashMessage()[0];
    var authStorageService = Container.get(AuthStorageService);
    var history = useHistory();
    var usuario = useStore('usuario')[0];
    var loggedUser = authStorageService === null || authStorageService === void 0 ? void 0 : authStorageService.getUser();
    var _h = React.useState(), ranking = _h[0], setRanking = _h[1];
    var _j = React.useState(false), isLoading = _j[0], setIsLoading = _j[1];
    var loadedCourse = useStore('currentCourse')[0];
    var _k = React.useState(loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.nome), filterTitle = _k[0], setFilterTitle = _k[1];
    var coursesList = useQuery('courses', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_COURSES_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, error_1 === null || error_1 === void 0 ? void 0 : error_1.stack);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var sharedCoursesList = useQuery('shared-courses', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_SHARED_COURSES_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_2 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, error_2 === null || error_2 === void 0 ? void 0 : error_2.stack);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { enabled: false }).data;
    var cursosECursosInstaladosList = (_b = coursesList === null || coursesList === void 0 ? void 0 : coursesList.data) === null || _b === void 0 ? void 0 : _b.concat(sharedCoursesList === null || sharedCoursesList === void 0 ? void 0 : sharedCoursesList.data).sort(function (courseA, courseB) { return (courseA.nome > courseB.nome ? 1 : -1); });
    var groupList = useQuery('groups', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_3;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_GROUPS_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_3 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, error_3 === null || error_3 === void 0 ? void 0 : error_3.stack);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, { enabled: false }).data;
    var unidadesList = useQuery('unidades', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_4;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_UNIDADES_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_4 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, error_4 === null || error_4 === void 0 ? void 0 : error_4.stack);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var isCurrentUser = function (rankingEntry) {
        return (rankingEntry === null || rankingEntry === void 0 ? void 0 : rankingEntry.id) == loggedUser.usuarioId;
    };
    var _l = React.useState(false), openFilter = _l[0], setOpenFilter = _l[1];
    var handleFilterOnClick = function (_a) {
        var filterType = _a.filterType, courseId = _a.courseId, groupId = _a.groupId, unidadeId = _a.unidadeId, title = _a.title;
        return __awaiter(void 0, void 0, void 0, function () {
            var params, response, error_5;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        setIsLoading(true);
                        if (!!courseId) {
                            params = getQueryParams[FilterType.Course](courseId);
                        }
                        else if (!!groupId) {
                            params = getQueryParams[FilterType.Group](groupId);
                        }
                        else if (!!unidadeId) {
                            params = getQueryParams[FilterType.Unit](unidadeId);
                        }
                        else {
                            params = getQueryParams[filterType];
                        }
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4 /*yield*/, axiosClient.get(GET_RANKING_URL, { params: params })];
                    case 2:
                        response = _b.sent();
                        setRanking(response.data);
                        setFilterTitle(title);
                        setOpenFilter(false);
                        return [2 /*return*/, response.data];
                    case 3:
                        error_5 = _b.sent();
                        showFlashMessage(FlashMessageEnum.alert, error_5 === null || error_5 === void 0 ? void 0 : error_5.stack);
                        return [3 /*break*/, 5];
                    case 4:
                        setIsLoading(false);
                        return [7 /*endfinally*/];
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    React.useEffect(function () {
        if (!!(loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.id)) {
            handleFilterOnClick({ courseId: loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.id, title: loadedCourse === null || loadedCourse === void 0 ? void 0 : loadedCourse.nome });
        }
        else {
            handleFilterOnClick({ filterType: FilterType.Weekly, title: 'Semanal' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    var navigateToQuizResultPage = function () {
        history.push({
            pathname: AppPath.QuizWin,
            state: {
                quizResult: data,
                tarefa: tarefa,
            },
        });
    };
    var handleCompleteClick = function () {
        if (isProfile) {
            history.push(AppPath.Map);
        }
        else {
            if (usuario.isUsuarioExterno) {
                history.push(AppPath.Map); // temporario, pois estava com  bug a tela de resultado de quiz
            }
            else {
                navigateToQuizResultPage();
            }
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Col, { xs: 12 },
            React.createElement(Row, { center: "xs" },
                React.createElement(FilterContainerStyled, { onMouseLeave: function () { return setOpenFilter(false); } },
                    React.createElement(Hbox, { onClick: function () { return setOpenFilter(!openFilter); }, style: { cursor: 'pointer' } },
                        React.createElement(Hbox.Item, null, filterTitle),
                        React.createElement(Hbox.Separator, null),
                        React.createElement(Hbox.Item, { noGrow: true },
                            React.createElement(FaIcon.ChevronDown, null))),
                    openFilter && (React.createElement(FilterOptionsStyled, null,
                        React.createElement(Body, null, "Per\u00EDodo"),
                        React.createElement(Separator, { isSmall: true }),
                        React.createElement(FiltersGroup, null,
                            React.createElement(Filter, { text: "Geral", color: filterTitle === 'Geral' ? darken(0.5, theme.corPrimaria) : theme.corPrimaria, onClick: function () { return handleFilterOnClick({ filterType: FilterType.General, title: 'Geral' }); } }),
                            React.createElement(Filter, { text: "Semanal", color: filterTitle === 'Semanal' ? darken(0.2, theme.corPrimaria) : theme.corPrimaria, onClick: function () { return handleFilterOnClick({ filterType: FilterType.Weekly, title: 'Semanal' }); } })),
                        React.createElement(Separator, { isSmall: true }),
                        !!(groupList === null || groupList === void 0 ? void 0 : groupList.data) && (React.createElement(React.Fragment, null,
                            React.createElement(Body, null, "Grupo"),
                            React.createElement(Separator, { isSmall: true }),
                            React.createElement(FiltersGroup, null, (_c = groupList === null || groupList === void 0 ? void 0 : groupList.data) === null || _c === void 0 ? void 0 : _c.map(function (group) { return (React.createElement(Filter, { key: group === null || group === void 0 ? void 0 : group.id, text: group === null || group === void 0 ? void 0 : group.nome, color: theme.corPrimaria, onClick: function () { return handleFilterOnClick({ groupId: group === null || group === void 0 ? void 0 : group.id, title: group === null || group === void 0 ? void 0 : group.nome }); } })); })),
                            React.createElement(Separator, { isSmall: true }))),
                        !!(unidadesList === null || unidadesList === void 0 ? void 0 : unidadesList.data) && (React.createElement(React.Fragment, null,
                            React.createElement(Body, null, "Unidades"),
                            React.createElement(Separator, { isSmall: true }),
                            React.createElement(FiltersGroup, null, (_d = unidadesList === null || unidadesList === void 0 ? void 0 : unidadesList.data) === null || _d === void 0 ? void 0 : _d.map(function (unidade) { return (React.createElement(Filter, { key: unidade === null || unidade === void 0 ? void 0 : unidade.id, text: unidade === null || unidade === void 0 ? void 0 : unidade.nome, color: theme.corPrimaria, onClick: function () { return handleFilterOnClick({ unidadeId: unidade === null || unidade === void 0 ? void 0 : unidade.id, title: unidade === null || unidade === void 0 ? void 0 : unidade.nome }); } })); })),
                            React.createElement(Separator, { isSmall: true }))),
                        !!(coursesList === null || coursesList === void 0 ? void 0 : coursesList.data) && (React.createElement(React.Fragment, null,
                            React.createElement(Body, null, "Desafio"),
                            React.createElement(Separator, { isSmall: true }),
                            React.createElement(FiltersGroup, null, cursosECursosInstaladosList.map(function (course) { return (React.createElement(Filter, { key: course === null || course === void 0 ? void 0 : course.id, text: course === null || course === void 0 ? void 0 : course.nome, color: theme.corPrimaria, onClick: function () { return handleFilterOnClick({ courseId: course === null || course === void 0 ? void 0 : course.id, title: course === null || course === void 0 ? void 0 : course.nome }); } })); })),
                            React.createElement(Separator, { isSmall: true })))))))),
        React.createElement(Separator, null),
        React.createElement(Ranking, null,
            isLoading && (React.createElement("div", { style: { textAlign: 'center', height: '50px' } },
                React.createElement(ActivityIndicator, { type: "spinner" }))),
            !!(ranking === null || ranking === void 0 ? void 0 : ranking.data) && ranking.data.length === 0 && !isLoading && (React.createElement("div", { style: { textAlign: 'center', marginBottom: '20px' } }, "Sem registros")),
            !isLoading && ((_e = ranking === null || ranking === void 0 ? void 0 : ranking.data) === null || _e === void 0 ? void 0 : _e.slice(0, 50).map(function (rankingEntry, idx) { return (React.createElement(RankingTableItem, { key: idx, isCurrentUser: isCurrentUser(rankingEntry), rankingEntry: rankingEntry })); })),
            !isLoading && ((_f = ranking === null || ranking === void 0 ? void 0 : ranking.data) === null || _f === void 0 ? void 0 : _f.map(function (rankingEntry) {
                return (loggedUser.usuarioId === (rankingEntry === null || rankingEntry === void 0 ? void 0 : rankingEntry.id) &&
                    rankingEntry.posicao > 50 && (React.createElement(RankingTableItem, { key: rankingEntry === null || rankingEntry === void 0 ? void 0 : rankingEntry.id, isCurrentUser: true, rankingEntry: rankingEntry })));
            }))),
        !ocultarBotaoConcluir && (React.createElement(Button, { expanded: true, onClick: handleCompleteClick },
            React.createElement("span", null, "CONCLUIR")))));
};
