var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { useStore } from '@app/core/services';
import { ActionPlanModal } from '@app/modules/checklist/components/action-plan-modal-view';
import { ModalFoto } from '@app/modules/checklist/components/modal-foto';
import { ModalObservacao } from '@app/modules/checklist/components/modal-observacao';
import { useUserStore } from '@app/providers';
import { AppPath } from '@app/routes';
import { getUserLocation } from '@app/services/location';
import { ActivityIndicator, Button, FlashMessageEnum } from '@atomic';
import { Span } from '../components/checklist/checklist.styled';
import { ChecklistRecusadoItemDetalhado } from './checklist-denied-item-detalhado.component';
export var ITEM_STATE = {
    NOT_ANSWERED: 'not_answered',
    RIGHT: 'right',
    WRONG: 'wrong',
};
export var ChecklistDenied = function (_a) {
    var _b, _c, _d, _e;
    var checklist = _a.checklist, isLoadingChecklists = _a.isLoadingChecklists;
    var history = useHistory();
    var showFlashMessage = useFlashMessage()[0];
    var userData = useStore(useUserStore).userData;
    var _f = useState(false), isModalOpened = _f[0], setIsModalOpened = _f[1];
    var _g = useState(false), isModalObservacaoOpened = _g[0], setIsModalObservacaoOpened = _g[1];
    var _h = useState(false), isModalFotoOpened = _h[0], setIsModalFotoOpened = _h[1];
    var _j = useState([]), respostasGestor = _j[0], setRespostasGestor = _j[1];
    var _k = useState([]), observacaoItemsIds = _k[0], setObservacaoItemsIds = _k[1];
    var _l = useState([]), actionPlansChecklistItemsIds = _l[0], setActionPlansChecklistItemsIds = _l[1];
    var _m = useState([]), fotosEnviadasChecklistItemsIds = _m[0], setFotosEnviadasChecklistItemsIds = _m[1];
    var _o = useState(), currentRespostaItemId = _o[0], setCurrentRespostaItemId = _o[1];
    var _p = useState(''), dataInicioResposta = _p[0], setDataInicioResposta = _p[1];
    var _q = useState(checklist), currentChecklist = _q[0], setCurrentChecklist = _q[1];
    var _r = useState(checklist), oldChecklist = _r[0], setOldChecklist = _r[1];
    var _s = useState(false), isAnyObjectWithRequiredPhotoSent = _s[0], setIsAnyObjectWithRequiredPhotoSent = _s[1];
    var _t = useState(), currentChecklistItem = _t[0], setCurrentChecklistItem = _t[1];
    var _u = useState(false), actionPlanSent = _u[0], setActionPlanSent = _u[1];
    var _v = useState(false), photoSent = _v[0], setPhotoSent = _v[1];
    var _w = useState(false), observacaoSent = _w[0], setObservacaoSent = _w[1];
    var _x = useState([]), respostasIdEnviadas = _x[0], setRespostasIdEnviadas = _x[1];
    var _y = useState(false), loading = _y[0], setLoading = _y[1];
    var _z = useState(false), clearToFinish = _z[0], setClearToFinish = _z[1];
    var _0 = useState(), currentFotoPreview = _0[0], setCurrentFotoPreview = _0[1];
    var _1 = useState([]), fotos = _1[0], setFotos = _1[1];
    var _2 = useState(false), isWaitingResponse = _2[0], setIsWaitingResponse = _2[1];
    var POST_ANSWERED_CHECKLIST = 'checklistunidade/preenchido-finalizar';
    var POST_ANSWERED_CHECKLIST_INDIVIDUAL = 'checklistunidade/preenchido-individual';
    var sortItemsByOrder = function (itens) {
        if (itens)
            return itens.slice().sort(function (a, b) { return a.ordem - b.ordem; });
        return [];
    };
    var postChecklistMutation = function () { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, response;
        var _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    formattedData = {
                        id: currentChecklist.preenchidoId,
                        unidadeEmpresaId: checklist.unidadeEmpresaId,
                        checklistUnidadeId: (_a = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _a === void 0 ? void 0 : _a.id,
                        gestorResponsavelId: userData.usuarioId,
                        dataInicioResposta: dataInicioResposta,
                        checklistUnidadePreenchidoIdAnterior: checklist === null || checklist === void 0 ? void 0 : checklist.id,
                        respostas: respostasGestor.map(function (resposta) {
                            return {
                                checklistUnidadeItemId: resposta.id,
                                isEfetivo: resposta.efetivo,
                                dataResposta: resposta.dataResposta,
                                latitudeResposta: resposta.latitudeResposta,
                                longitudeResposta: resposta.longitudeResposta,
                            };
                        }),
                    };
                    return [4 /*yield*/, axiosClient.put(POST_ANSWERED_CHECKLIST, formattedData)];
                case 1:
                    response = _b.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); };
    var _3 = useMutation(postChecklistMutation, {
        onSuccess: function () {
            showFlashMessage(FlashMessageEnum.success, 'Checklist finalizado com sucesso!');
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao finalizar o checklist');
        },
    }), postChecklistResponse = _3.data, mutate = _3.mutate, isLoading = _3.isLoading;
    useEffect(function () {
        setLoading(true);
        if (dataInicioResposta === '') {
            setDataInicioResposta(moment().format());
        }
        setCurrentChecklist(checklist);
        setOldChecklist(checklist);
        setTimeout(function () {
            setLoading(false);
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checklist]);
    var handleOnSubmitClick = function () {
        mutate();
    };
    var idsThatNeedPhoto = (_c = (_b = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _b === void 0 ? void 0 : _b.itens) === null || _c === void 0 ? void 0 : _c.filter(function (obj) { return obj.fotoObrigatoria; }).map(function (obj) { return obj.respostas[0].id; });
    useEffect(function () {
        var hasRequiredPhotoSent = idsThatNeedPhoto === null || idsThatNeedPhoto === void 0 ? void 0 : idsThatNeedPhoto.every(function (_id) { return fotosEnviadasChecklistItemsIds.includes(_id); });
        setIsAnyObjectWithRequiredPhotoSent(hasRequiredPhotoSent);
    }, [checklist, fotosEnviadasChecklistItemsIds, idsThatNeedPhoto]);
    var registerItemAnswer = function (checklistItemId, isEfetivo, coordenadas) {
        var _a;
        var formattedData = {
            id: currentChecklist.preenchidoId ? currentChecklist.preenchidoId : 0,
            unidadeEmpresaId: checklist.unidadeEmpresaId,
            checklistUnidadeId: (_a = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _a === void 0 ? void 0 : _a.id,
            gestorResponsavelId: userData.usuarioId,
            dataInicioResposta: dataInicioResposta,
            resposta: {
                id: 0,
                checklistUnidadeItemId: checklistItemId,
                isEfetivo: isEfetivo,
                dataResposta: moment().format(),
                latitudeResposta: coordenadas ? coordenadas.latitude : null,
                longitudeResposta: coordenadas ? coordenadas.longitude : null,
            },
        };
        return formattedData;
    };
    var handleOnChecklistItemAnswer = function (checklistItemId, isEfetivo) { return __awaiter(void 0, void 0, void 0, function () {
        var coordenadas, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsWaitingResponse(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, getUserLocation()];
                case 2:
                    coordenadas = _a.sent();
                    handlePreenchidoIndividual(checklistItemId, isEfetivo, coordenadas);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    setIsWaitingResponse(false);
                    handlePreenchidoIndividual(checklistItemId, isEfetivo);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handlePreenchidoIndividual = function (checklistItemId, isEfetivo, coordenadas) { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, response, error_1;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    _f.trys.push([0, 2, , 3]);
                    setIsWaitingResponse(true);
                    formattedData = registerItemAnswer(checklistItemId, isEfetivo, coordenadas);
                    formattedData = __assign(__assign({}, formattedData), { id: Number(currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.preenchidoId) || 0 });
                    return [4 /*yield*/, axiosClient.post(POST_ANSWERED_CHECKLIST_INDIVIDUAL, formattedData)];
                case 1:
                    response = _f.sent();
                    setCurrentChecklist(__assign(__assign({}, currentChecklist), { preenchidoId: (_b = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.id, respostaId: (_e = (_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.respostas[0]) === null || _e === void 0 ? void 0 : _e.id }));
                    if (!respostasIdEnviadas.includes(checklistItemId)) {
                        setRespostasIdEnviadas(__spreadArrays(respostasIdEnviadas, [checklistItemId]));
                    }
                    setIsWaitingResponse(false);
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _f.sent();
                    setIsWaitingResponse(false);
                    console.error('error', error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var getItemState = function (checklistItemId) {
        var itemRespondido = respostasGestor === null || respostasGestor === void 0 ? void 0 : respostasGestor.find(function (resposta) {
            return resposta.id == checklistItemId;
        });
        if ((itemRespondido === null || itemRespondido === void 0 ? void 0 : itemRespondido.efetivo) !== undefined) {
            return itemRespondido.efetivo ? ITEM_STATE.RIGHT : ITEM_STATE.WRONG;
        }
        else {
            return ITEM_STATE.NOT_ANSWERED;
        }
    };
    var _4 = useState(), actionPlanIdSent = _4[0], setActionPlanIdSent = _4[1];
    var handleOnActionPlanClick = function (checklistUnidadeItem) {
        var _a;
        setCurrentRespostaItemId(checklistUnidadeItem === null || checklistUnidadeItem === void 0 ? void 0 : checklistUnidadeItem.id);
        setCurrentChecklistItem(checklistUnidadeItem);
        setActionPlanIdSent((_a = checklistUnidadeItem === null || checklistUnidadeItem === void 0 ? void 0 : checklistUnidadeItem.respostas[0]) === null || _a === void 0 ? void 0 : _a.id);
        setIsModalOpened(true);
    };
    var handleObservacaoClick = function (checklistUnidadeRespostaId) {
        setCurrentRespostaItemId(checklistUnidadeRespostaId);
        setIsModalObservacaoOpened(true);
    };
    var handleOnEnviarFotoClick = function (checklistUnidadeResposta) {
        var ft = fotos.find(function (foto) { return foto.id === (checklistUnidadeResposta === null || checklistUnidadeResposta === void 0 ? void 0 : checklistUnidadeResposta.checklistUnidadeItemId); });
        setCurrentFotoPreview(ft);
        setCurrentRespostaItemId(checklistUnidadeResposta === null || checklistUnidadeResposta === void 0 ? void 0 : checklistUnidadeResposta.id);
        setIsModalFotoOpened(true);
    };
    var handleOnFotoModalClose = function () {
        setIsModalFotoOpened(false);
        setCurrentRespostaItemId(undefined);
    };
    var handleObservacaoModalClose = function () {
        setIsModalObservacaoOpened(false);
        setCurrentRespostaItemId(undefined);
    };
    var handleOnActionPlanModalClose = function () {
        setIsModalOpened(false);
        setCurrentRespostaItemId(undefined);
    };
    var handleOnCloseClick = function () {
        history.push(AppPath.RoutineChecklist);
    };
    var registerActionPlan = function () {
        setActionPlanSent(true);
        setActionPlansChecklistItemsIds(function (old) { return __spreadArrays(old, [actionPlanIdSent]); });
    };
    var registerFotoEnviada = function () {
        setPhotoSent(true);
        setFotosEnviadasChecklistItemsIds(function (old) { return __spreadArrays(old, [currentRespostaItemId]); });
    };
    var registerObservacao = function () {
        setObservacaoSent(true);
        setObservacaoItemsIds(function (old) { return __spreadArrays(old, [currentRespostaItemId]); });
    };
    var handleItemPreenchidoClicked = function (itemId, efectiveness, item) {
        setObservacaoSent(false);
        setPhotoSent(false);
        setActionPlanSent(false);
        setCurrentChecklistItem(item);
        handleOnChecklistItemAnswer(itemId, efectiveness);
    };
    useEffect(function () {
        var _a, _b;
        var isRespostasLengthEqualOrHigher = (respostasIdEnviadas === null || respostasIdEnviadas === void 0 ? void 0 : respostasIdEnviadas.length) >= ((_b = (_a = oldChecklist === null || oldChecklist === void 0 ? void 0 : oldChecklist.checklistUnidade) === null || _a === void 0 ? void 0 : _a.itens) === null || _b === void 0 ? void 0 : _b.length);
        if (isRespostasLengthEqualOrHigher) {
            setClearToFinish(true);
        }
        else {
            setClearToFinish(false);
        }
    }, [respostasIdEnviadas, oldChecklist]);
    var removerFotoEnviada = function () {
        setPhotoSent(false);
        setFotosEnviadasChecklistItemsIds(undefined);
        setCurrentFotoPreview(undefined);
    };
    var addFotoPreview = function (newPreview) {
        // setCurrentFotoPreview(newPreview);
        setFotos(function (old) { return __spreadArrays(old, [newPreview]); });
    };
    var getFotosByItemId = function (itemId) {
        return fotos.find(function (foto) { return foto.id === itemId; });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ActionPlanModal, { isOpened: isModalOpened, onSend: registerActionPlan, handleOnClose: handleOnActionPlanModalClose, currentPreenchidoId: currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.preenchidoId, isChecklistUnidade: true, currentRespostaGestorId: userData === null || userData === void 0 ? void 0 : userData.usuarioId, item: currentChecklistItem, planoAcao: (_d = currentChecklistItem === null || currentChecklistItem === void 0 ? void 0 : currentChecklistItem.respostas[0]) === null || _d === void 0 ? void 0 : _d.planoAcaoChecklistUnidade, respostaId: currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.respostaId }),
        React.createElement(ModalFoto, { isOpened: isModalFotoOpened, onSend: registerFotoEnviada, handleOnClose: handleOnFotoModalClose, isChecklistUnidade: true, checklist: currentChecklistItem, currentRespostaItemId: currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.respostaId, setFotosPreview: addFotoPreview, itemFotos: currentFotoPreview, isPermitidoImagemDaBiblioteca: checklist === null || checklist === void 0 ? void 0 : checklist.isPermitidoImagemDaBiblioteca }),
        React.createElement(ModalObservacao, { isOpened: isModalObservacaoOpened, onSend: registerObservacao, handleOnClose: handleObservacaoModalClose, isChecklistUnidade: true, checklist: currentChecklistItem, currentRespostaItemId: currentChecklist === null || currentChecklist === void 0 ? void 0 : currentChecklist.respostaId }),
        React.createElement(Span, null, "Itens marcados com * s\u00E3o obrigat\u00F3rios."),
        !isLoadingChecklists ? (React.createElement(React.Fragment, null, sortItemsByOrder((_e = oldChecklist === null || oldChecklist === void 0 ? void 0 : oldChecklist.checklistUnidade) === null || _e === void 0 ? void 0 : _e.itens).map(function (item, index) { return (React.createElement(React.Fragment, null,
            React.createElement(ChecklistRecusadoItemDetalhado, { key: "" + index + item.id, checklistItem: item, handleOnClick: handleItemPreenchidoClicked, itemState: getItemState(item.id), handleOnActionPlanClick: function () { return handleOnActionPlanClick(item); }, handleOnEnviarFotoClick: function () { return handleOnEnviarFotoClick(item.respostas[0]); }, handleOnObservacaoClick: function () { var _a; return handleObservacaoClick((_a = item.respostas[0]) === null || _a === void 0 ? void 0 : _a.id); }, isActionPlanRegistered: !!actionPlansChecklistItemsIds.find(function (id) { var _a; return id === ((_a = item.respostas[0]) === null || _a === void 0 ? void 0 : _a.id); }), isFotoEnviada: !!fotosEnviadasChecklistItemsIds.find(function (id) { var _a; return id === ((_a = item.respostas[0]) === null || _a === void 0 ? void 0 : _a.id); }), isObservacaoEnviada: !!observacaoItemsIds.find(function (id) { var _a; return id === ((_a = item.respostas[0]) === null || _a === void 0 ? void 0 : _a.id); }), fotoPreview: getFotosByItemId(item.id), removeFotoPreview: removerFotoEnviada, boxBackgroundColor: index % 2 === 0 ? '#ECFDFD' : 'rgba(239, 255, 255, 0.42)', isDisabled: isWaitingResponse || isLoading }))); }))) : (React.createElement(ActivityIndicator, { type: "spinner" })),
        postChecklistResponse ? (React.createElement(Button, { loading: isLoading, onClick: handleOnCloseClick, style: { background: '#008425', borderColor: '#008425', marginTop: '15px' }, expanded: true }, "Fechar")) : (React.createElement(React.Fragment, null,
            React.createElement(Button, { loading: isLoading, disabled: !clearToFinish || !isAnyObjectWithRequiredPhotoSent, onClick: handleOnSubmitClick, style: { background: '#008425', borderColor: '#008425', marginTop: '15px' }, expanded: true }, "Enviar")))));
};
