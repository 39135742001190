var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Header } from '@app/components/mol.header';
import { BottomNav } from '@app/components/mol.track-bottom-nav';
import { AppPath } from '@app/routes';
import newCheckIcon from '@assets/icons/new-check-icon.svg';
import { FontFamily, Button, Col, StyledContainer } from '@atomic';
import { SubmenuHeader } from '../submenus/components/styles';
import { FlexCol } from '../submenus/components/submenu-content-card/styles';
var Box = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 100%;\n  max-width: 300px;\n  aspect-ratio: 1 / 1;\n  border: 2px solid #d9d9d9;\n  border-radius: 8px;\n  padding: 16px;\n  box-sizing: border-box;\n"], ["\n  width: 100%;\n  max-width: 300px;\n  aspect-ratio: 1 / 1;\n  border: 2px solid #d9d9d9;\n  border-radius: 8px;\n  padding: 16px;\n  box-sizing: border-box;\n"])));
export var ChecklistFinishedPage = function () {
    var history = useHistory();
    var location = useLocation();
    var extraData = (location.state || {}).extraData;
    var navigateToChecklistDetails = function () {
        if (extraData === null || extraData === void 0 ? void 0 : extraData.checklistPai) {
            history.push({
                pathname: AppPath.ActionPlanChecklistDetails.replace(':id', extraData === null || extraData === void 0 ? void 0 : extraData.id.toString()),
                state: { isHistory: true },
            });
        }
        else {
            history.push(AppPath.ChecklistUnidadeDetails.replace(':id', extraData === null || extraData === void 0 ? void 0 : extraData.id.toString()).replace(':uniEmpId', extraData === null || extraData === void 0 ? void 0 : extraData.unidadeEmpresaId.toString()));
        }
        /*
        history.push(
          AppPath.ChecklistUnidadeDetails.replace(':id', extraData?.id.toString()).replace(
            ':uniEmpId',
            extraData?.unidadeEmpresaId.toString(),
          ),
        );
        */
    };
    var navigateToRoutineChecklist = function () {
        history.push(AppPath.RoutineChecklist);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: 'use-inverse-colors' },
            React.createElement(Header, { withLogoutButton: true, withBackButton: false }),
            React.createElement("br", null)),
        React.createElement(Col, { style: { paddingTop: '50px' } },
            React.createElement("br", null),
            React.createElement(SubmenuHeader, null,
                React.createElement(Typography, { sx: { fontFamily: FontFamily.Inter700, fontSize: '24px', color: '#49443C' }, variant: "h1", component: "div" }, "Checklist")),
            React.createElement("div", { style: { padding: '20px', display: 'flex', justifyContent: 'center', color: '#49443C', fontSize: '20px' } }, extraData === null || extraData === void 0 ? void 0 : extraData.nome),
            React.createElement("div", { style: { padding: '20px', display: 'flex', justifyContent: 'center' } },
                React.createElement(Box, null,
                    React.createElement(FlexCol, { style: { alignItems: 'center', gap: '16px', marginTop: '24px' } },
                        React.createElement(Typography, { sx: {
                                fontFamily: FontFamily.Inter600,
                                fontSize: '16px',
                                color: '#000',
                                wordWrap: 'break-word',
                            }, component: "span", textAlign: "center", display: "block" }, "Checklist conclu\u00EDdo!"),
                        React.createElement("img", { src: newCheckIcon, alt: "page-complete-img", style: { width: '128px' } }),
                        React.createElement(Typography, { sx: {
                                fontFamily: FontFamily.Inter600,
                                fontSize: '14px',
                                color: '#000',
                                wordWrap: 'break-word',
                            }, component: "span", textAlign: "center", display: "block" }, (extraData === null || extraData === void 0 ? void 0 : extraData.nota) >= 0 && React.createElement(React.Fragment, null,
                            "Sua nota foi ", extraData === null || extraData === void 0 ? void 0 :
                            extraData.nota,
                            "%"))))),
            React.createElement(Button, { variant: "primary", onClick: navigateToRoutineChecklist, expanded: true }, "VOLTAR PARA CHECKLISTS"),
            React.createElement("br", null),
            React.createElement("br", null),
            (extraData === null || extraData === void 0 ? void 0 : extraData.id) && (React.createElement(Button, { variant: "secondary", onClick: navigateToChecklistDetails, expanded: true }, "IR PARA HIST\u00D3RICO"))),
        React.createElement("div", { style: { height: '100px' } },
            React.createElement(BottomNav, { isChecklist: true }))));
};
var templateObject_1;
