var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { H4, H6 } from '@atomic';
export var Question = styled(H6)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  font-size: 1.1rem;\n  font-weight: 700;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n"], ["\n  font-size: 1.1rem;\n  font-weight: 700;\n  margin-top: 10px;\n  margin-bottom: 10px;\n  padding-top: 10px;\n  padding-bottom: 10px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n"])));
export var RightAnswer = styled(H6)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  font-ize: 1.1rem;\n  font-weight: 100;\n  margin-top: 0;\n  margin-bottom: 15px;\n"], ["\n  font-ize: 1.1rem;\n  font-weight: 100;\n  margin-top: 0;\n  margin-bottom: 15px;\n"])));
export var ItemButtonsWrapper = styled(H6)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 1.2rem;\n  margin-top: 0.5rem;\n  column-gap: 20px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  margin-bottom: 1.2rem;\n  margin-top: 0.5rem;\n  column-gap: 20px;\n"])));
export var ActionsWrapper = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: space-around;\n  margin-bottom: 0.5rem;\n"], ["\n  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */\n  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */\n  display: -ms-flexbox; /* TWEENER - IE 10 */\n  display: -webkit-flex; /* NEW - Chrome */\n  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */\n  justify-content: space-around;\n  margin-bottom: 0.5rem;\n"])));
export var ActionPlan = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: color: #49443C;;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"], ["\n  color: color: #49443C;;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"])));
export var ActionPlanSubmittedInfo = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  column-gap: 5px;\n  border: 1px solid #49443c;\n  border-radius: 8px;\n  padding-inline: 10px;\n  height: 30px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  column-gap: 5px;\n  border: 1px solid #49443c;\n  border-radius: 8px;\n  padding-inline: 10px;\n  height: 30px;\n"])));
export var EnviarFoto = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: #49443c;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"], ["\n  color: #49443c;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"])));
export var EnviarFotoSubmittedInfo = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  column-gap: 5px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  column-gap: 5px;\n"])));
export var EnviarObservacao = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  color: #49443c;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"], ["\n  color: #49443c;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n"])));
export var EnviarObservacaoSubmittedInfo = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  column-gap: 5px;\n"], ["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  justify-content: space-between;\n  column-gap: 5px;\n"])));
export var Title = styled(H4)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"], ["\n  margin-top: 0.5rem;\n  margin-bottom: 1rem;\n  text-align: center;\n  color: ", ";\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var ActionPlanLabel = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  min-width: 140px;\n  height: 30px;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #008425;\n  border-radius: 8px;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  column-gap: 5px;\n\n  span {\n    color: #49443c;\n    display: table;\n    font-size: 16px;\n    margin: 0 auto;\n    line-height: 25px;\n  }\n"], ["\n  min-width: 140px;\n  height: 30px;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #008425;\n  border-radius: 8px;\n  text-align: center;\n  display: flex;\n  align-items: center;\n  column-gap: 5px;\n\n  span {\n    color: #49443c;\n    display: table;\n    font-size: 16px;\n    margin: 0 auto;\n    line-height: 25px;\n  }\n"])));
export var PendingLabel = styled.div(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  min-width: 80px;\n  height: 25px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #eec800;\n  border-radius: 16px;\n\n  span {\n    color: #eec800;\n    display: table;\n    margin: 0 auto;\n  }\n"], ["\n  min-width: 80px;\n  height: 25px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #eec800;\n  border-radius: 16px;\n\n  span {\n    color: #eec800;\n    display: table;\n    margin: 0 auto;\n  }\n"])));
export var LateLabel = styled.div(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  min-width: 80px;\n  height: 25px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #f37500;\n  border-radius: 16px;\n\n  span {\n    color: #f37500;\n    display: table;\n    margin: 0 auto;\n  }\n"], ["\n  min-width: 80px;\n  height: 25px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #f37500;\n  border-radius: 16px;\n\n  span {\n    color: #f37500;\n    display: table;\n    margin: 0 auto;\n  }\n"])));
export var CompliantLabel = styled.div(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  min-width: 80px;\n  height: 25px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #008425;\n  border-radius: 16px;\n\n  span {\n    color: #008425;\n    display: table;\n    margin: 0 auto;\n  }\n"], ["\n  min-width: 80px;\n  height: 25px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #008425;\n  border-radius: 16px;\n\n  span {\n    color: #008425;\n    display: table;\n    margin: 0 auto;\n  }\n"])));
export var NonCompliantLabel = styled.div(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  min-width: 120px;\n  height: 25px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #c72000;\n  border-radius: 16px;\n\n  span {\n    color: #c72000;\n    display: table;\n    margin: 0 auto;\n  }\n"], ["\n  min-width: 120px;\n  height: 25px;\n  font-size: 0.9rem;\n  background-color: #ffffff;\n  color: #f37500;\n  border: 2px solid #c72000;\n  border-radius: 16px;\n\n  span {\n    color: #c72000;\n    display: table;\n    margin: 0 auto;\n  }\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16;
