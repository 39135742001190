import Resizer from 'react-image-file-resizer';
// Function to convert Base64 to File
var base64ToFile = function (base64String, fileName) {
    var arr = base64String.split(',');
    var mime = arr[0].match(/:(.*?);/)[1];
    var bstr = atob(arr[1]);
    var n = bstr.length;
    var u8arr = new Uint8Array(n);
    for (var i = 0; i < n; i++) {
        u8arr[i] = bstr.charCodeAt(i);
    }
    return new File([u8arr], fileName, { type: mime });
};
export var compressFileImage = function (file, maxWidth, maxHeight, quality) {
    if (maxWidth === void 0) { maxWidth = 1024; }
    if (maxHeight === void 0) { maxHeight = 1024; }
    if (quality === void 0) { quality = 0.7; }
    return new Promise(function (resolve, reject) {
        // If necessary to convert from Base64 to a compressed Base64 use this function
        // const file = base64ToFile(base64String, 'image.jpg');
        Resizer.imageFileResizer(file, maxWidth, maxHeight, 'JPEG', // or 'PNG'
        quality * 100, // Convert to percentage
        0, // Rotation
        function (uri) {
            resolve(uri); // The result is returned as a Base64 string
        }, 'base64');
    });
};
