var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useQuery } from 'react-query';
import { axiosClient } from '@app/configure-app';
import { formatToPtBrDateTime } from '@app/utils/shared-functions.utils';
import { ActivityIndicator } from '@atomic';
import { Col, Row, StyledContainer } from '@atomic';
import ProfileHeader from '../player-profile/components/profile-header';
import { NotificationCard } from './components/notification-card.component';
import { ApagarButton, FlexRowSimple, LoadMoreInfo, TitlePageText } from './components/styles';
import { NotificationFilter } from './INotification';
import { ModalLimparNotificacoes } from './modal-limpar-notificacoes/modal-limpar-notificacoes.component';
var userUrl = 'usuario';
export var NotificationsPage = function () {
    var _a;
    var _b = React.useState(false), loadingMore = _b[0], setLoadingMore = _b[1];
    var _c = React.useState([]), notifications = _c[0], setNotifications = _c[1];
    var _d = React.useState(NotificationFilter.ALL), notificationFilter = _d[0], setNotificationFilter = _d[1];
    var _e = React.useState(false), modalNotificationOpened = _e[0], setModalNotificationOpened = _e[1];
    var _f = React.useState(undefined), pageData = _f[0], setPageData = _f[1];
    var userResponse = useQuery('user', function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(userUrl)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    console.error(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var LIST_NOTIFICATIONS_URL = "notificacao-usuario/list";
    var CLEAR_NOTIFICATIONS_URL = "notificacao-usuario/limpar-notificacoes";
    var _g = useQuery(['list-notificacoes', notificationFilter], function () { return __awaiter(void 0, void 0, void 0, function () {
        var url, response, data, results, err_1;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _c.trys.push([0, 2, , 3]);
                    url = LIST_NOTIFICATIONS_URL + (notificationFilter == NotificationFilter.UNREAD ? '?lido=false' : '');
                    return [4 /*yield*/, axiosClient.get(url)];
                case 1:
                    response = _c.sent();
                    data = (_a = response.data) === null || _a === void 0 ? void 0 : _a.data;
                    results = ((_b = data === null || data === void 0 ? void 0 : data.results) !== null && _b !== void 0 ? _b : []).map(function (item) {
                        var notif = {
                            id: item.id,
                            email: item.email,
                            usuarioId: item.usuarioId,
                            empresaId: item.empresaId,
                            entityId: item.entityId,
                            titulo: item.titulo,
                            entityType: item.entityType,
                            lido: item.lido,
                            data: item.data ? formatToPtBrDateTime(item.data) : undefined,
                            dataCadastro: item.dataCadastro ? formatToPtBrDateTime(item.dataCadastro) : undefined,
                            dataLido: item.dataLido ? formatToPtBrDateTime(item.dataLido) : undefined,
                        };
                        return notif;
                    });
                    setPageData(data);
                    setNotifications(results);
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _c.sent();
                    console.log(err_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }, {
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        retry: true,
    }), isLoading = _g.isLoading, isFetching = _g.isFetching, refetch = _g.refetch;
    var reloadNotifications = function (pagination) { return __awaiter(void 0, void 0, void 0, function () {
        var pageNumber, pageSize, url, resp, data, results;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    setLoadingMore(true);
                    pageNumber = ((_a = pagination.pageNumber) !== null && _a !== void 0 ? _a : 0) + 1;
                    pageSize = (_b = pagination.pageSize) !== null && _b !== void 0 ? _b : 10;
                    url = LIST_NOTIFICATIONS_URL + '?pageNumber=' + pageNumber + '&pageSize=' + pageSize;
                    url += notificationFilter == NotificationFilter.UNREAD ? '&lido=false' : '';
                    return [4 /*yield*/, axiosClient.get(url)];
                case 1:
                    resp = _e.sent();
                    data = (_c = resp.data) === null || _c === void 0 ? void 0 : _c.data;
                    results = ((_d = data === null || data === void 0 ? void 0 : data.results) !== null && _d !== void 0 ? _d : []).map(function (item) {
                        var notif = {
                            id: item.id,
                            email: item.email,
                            usuarioId: item.usuarioId,
                            empresaId: item.empresaId,
                            entityId: item.entityId,
                            titulo: item.titulo,
                            entityType: item.entityType,
                            lido: item.lido,
                            data: item.data ? formatToPtBrDateTime(item.data) : undefined,
                            dataCadastro: item.dataCadastro ? formatToPtBrDateTime(item.dataCadastro) : undefined,
                            dataLido: item.dataLido ? formatToPtBrDateTime(item.dataLido) : undefined,
                        };
                        return notif;
                    });
                    setPageData(data);
                    setNotifications(__spreadArrays(notifications, results));
                    setLoadingMore(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleClearNotificationClick = function () {
        setModalNotificationOpened(true);
    };
    var handleClearNotificationClose = function (filter) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post(CLEAR_NOTIFICATIONS_URL, { filter: filter })];
                case 1:
                    response = _a.sent();
                    setNotificationFilter(NotificationFilter.ALL);
                    refetch();
                    setModalNotificationOpened(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleNotificationFilter = function (event, filter) {
        switch (filter) {
            case NotificationFilter.ALL:
                setNotificationFilter(filter);
                break;
            case NotificationFilter.UNREAD:
                setNotificationFilter(filter);
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(ProfileHeader, { user: userResponse === null || userResponse === void 0 ? void 0 : userResponse.data }),
        React.createElement(ModalLimparNotificacoes, { open: modalNotificationOpened, onClose: handleClearNotificationClose }),
        React.createElement(StyledContainer, { withPadding: true, style: { padding: '1.2rem 1.8rem 6rem 1.8rem' } },
            React.createElement(FlexRowSimple, { style: { paddingLeft: '0px', paddingRight: '0px', marginBottom: '20px' } },
                React.createElement(TitlePageText, null, "Notifica\u00E7\u00F5es")),
            React.createElement(Row, { style: { marginTop: '10px', marginBottom: '15px' } },
                React.createElement(Col, { xs: 7 },
                    React.createElement(ToggleButtonGroup, { value: notificationFilter, exclusive: true, onChange: handleNotificationFilter, "aria-label": "Filtro de notifica\u00E7\u00F5es", style: { columnGap: '15px' } },
                        React.createElement(ToggleButton, { value: "all", "aria-label": "left aligned", style: {
                                height: '10px',
                                border: '1px solid #49443C',
                                borderRadius: '5px',
                                color: '#49443C',
                                textTransform: 'capitalize',
                            } }, "Tudo"),
                        React.createElement(ToggleButton, { value: "unread", "aria-label": "centered", style: {
                                height: '10px',
                                border: '1px solid #49443C',
                                borderRadius: '5px',
                                color: '#49443C',
                                textTransform: 'capitalize',
                                minWidth: '95px',
                            } }, "N\u00E3o Lidas"))),
                React.createElement(Col, { xs: 5, style: { display: 'flex', justifyContent: 'flex-end' } },
                    React.createElement(ApagarButton, { onClick: function () { return handleClearNotificationClick(); } },
                        React.createElement(DeleteOutlineOutlinedIcon, { sx: { fontSize: '1rem', color: '#49443C' } }),
                        React.createElement("span", null, "Limpar")))),
            (isLoading || isFetching) && (React.createElement(FlexRowSimple, { style: { width: '100%', alignItems: 'center', justifyContent: 'center' } },
                React.createElement(ActivityIndicator, { type: "spinner", size: "2x" }))),
            !(isLoading || isFetching) && (notifications === null || notifications === void 0 ? void 0 : notifications.map(function (notification, idx) { return (React.createElement(NotificationCard, { key: idx, notification: notification, isFirst: idx === 0, isLast: idx === notifications.length - 1 })); })),
            !(isLoading || isFetching) && ((_a = notifications === null || notifications === void 0 ? void 0 : notifications.length) !== null && _a !== void 0 ? _a : 0) == 0 && (React.createElement(FlexRowSimple, { style: { width: '100%', alignItems: 'center', justifyContent: 'center', padding: '10px' } }, 'Todas as notificações foram lidas')),
            !loadingMore && (pageData === null || pageData === void 0 ? void 0 : pageData.pageNumber) < (pageData === null || pageData === void 0 ? void 0 : pageData.totalPages) && (React.createElement(LoadMoreInfo, { onClick: function () { return reloadNotifications(pageData); }, style: { padding: '10px' } }, 'Ver mais')),
            loadingMore && (pageData === null || pageData === void 0 ? void 0 : pageData.pageNumber) < (pageData === null || pageData === void 0 ? void 0 : pageData.totalPages) && (React.createElement(FlexRowSimple, { style: { width: '100%', alignItems: 'center', justifyContent: 'center', padding: '10px' } },
                React.createElement(ActivityIndicator, { type: "spinner", size: "1x" }))))));
};
