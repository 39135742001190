import { format } from 'date-fns';
export var getPhaseNumber = function (idx) {
    if (idx > 9) {
        return idx.toString();
    }
    return '0' + idx;
};
export var formatToPtBrDate = function (utcDate) {
    try {
        return format(new Date(utcDate), 'dd/MM/yyyy');
    }
    catch (error) {
        return '';
    }
};
export var formatToPtBrDateTime = function (utcDate) {
    try {
        return format(new Date(utcDate), 'dd/MM/yyyy kk:mm:ss');
    }
    catch (error) {
        return '';
    }
};
export var removeSpecialChars = function (originalStr) {
    try {
        return originalStr.replace(/[^a-zA-Z0-9 ]/g, '');
    }
    catch (error) {
        return '';
    }
};
export var convertHexToRGB = function (hex, opacity) {
    if (opacity === void 0) { opacity = 1; }
    try {
        var r = parseInt(hex.slice(1, 3), 16);
        var g = parseInt(hex.slice(3, 5), 16);
        var b = parseInt(hex.slice(5, 7), 16);
        return "rgb(" + r + ", " + g + ", " + b + ", " + opacity.toFixed(1) + ")";
    }
    catch (error) {
        return '';
    }
};
export var getUsuarioIdFromClaims = function (claims) {
    return parseInt(claims.filter(function (claim) {
        return claim.type === 'UsuarioId';
    })[0].value);
};
export var getValueFromClaimsByType = function (claims, type) {
    var filteredType = claims.filter(function (claim) {
        return claim.type.includes(type);
    })[0].value;
    return filteredType;
};
export var unformatPhoneNumber = function (phoneNumber) {
    try {
        return phoneNumber.replace(/\D/g, '').replace(/\s/g, '');
    }
    catch (error) {
        return '';
    }
};
export var unformatCPF = function (cpf) {
    try {
        return cpf.replace(/\D/g, '');
    }
    catch (error) {
        return '';
    }
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export var convertFileToBase64 = function (file) {
    return new Promise(function (resolve, reject) {
        var fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = function () {
            resolve(fileReader.result);
        };
        fileReader.onerror = function (error) {
            reject(error);
        };
    });
};
export var getColorByValue = function (value) {
    if (value >= 0 && value < 30) {
        return 'red'; // Dark color for values between 0 and 30
    }
    else if (value >= 30 && value < 60) {
        return 'orange'; // Orange for values between 30 and 60
    }
    else if (value >= 60) {
        return 'green'; // Green for values between 60 and 100
    }
    else {
        throw new Error('Value must be between 0 and 100');
    }
};
