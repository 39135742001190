var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { Tooltip } from '@mui/material';
import { useStore } from 'react-context-hook';
import { useMutation } from 'react-query';
import { Link, useHistory } from 'react-router-dom';
import { Logo } from '@app/components/atm.logo/logo.component';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AuthPath } from '@app/modules/authentication/auth-path';
import { LoginFormFields } from '@app/modules/authentication/authentication.form-data';
import { AuthStrings } from '@app/modules/authentication/authentication.strings';
import { ArrowLeftStyled, ContainerStyled, ContentStyled } from '@app/modules/authentication/authentication.styled';
import { AppPath } from '@app/routes';
import { Form, Validators, Separator, TextField, Button, FaIcon, FlashMessageEnum } from '@atomic';
import { TelaDeLogin, Titulo, LogoWrapper, FormWrapper, ButtonsWrapper, DivLink } from './login.page-v2.styled';
var _a = AuthStrings.ForgotPassword, Title = _a.Title, ButtonText = _a.ButtonText;
var Login = AuthStrings.Commom.Login;
var CODIGO_SUCESSO_ENVIO_EMAIL_RECUP_SENHA = 'CODIGO_SUCESSO_ENVIO_EMAIL_RECUP_SENHA';
var CODIGO_USUARIO_SEM_EMAIL_NECESSARIO_VALIDAR_DADOS = 'CODIGO_USUARIO_SEM_EMAIL_NECESSARIO_VALIDAR_DADOS';
var forgotPasswordUrl = 'auth/ForgotPassword';
export var RecoverPasswordPage = function () {
    var showFlashMessage = useFlashMessage()[0];
    var history = useHistory();
    var domain = useStore('domain')[0];
    var company = useStore('company')[0];
    var _a = useMutation(function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axiosClient.post(forgotPasswordUrl, {
                        email: data.email,
                        cpf: data.cpf,
                        empresaIdentificador: domain,
                    })];
                case 1:
                    response = _a.sent();
                    if (!response.data) {
                        throw new Error();
                    }
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function (response) {
            if (response.data == CODIGO_SUCESSO_ENVIO_EMAIL_RECUP_SENHA) {
                showFlashMessage(FlashMessageEnum.success, 'E-mail para criação de nova senha enviado com sucesso!');
            }
            else if (response.data == CODIGO_USUARIO_SEM_EMAIL_NECESSARIO_VALIDAR_DADOS) {
                history.push({ pathname: AppPath.ValidacaoDados });
            }
            else {
                showFlashMessage(FlashMessageEnum.alert, 'Não foi possível validar. Confira os dados e tente novamente.');
            }
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro no processamento. Confira os dados e tente novamente.');
        },
        onSettled: function (response) {
            console.log('🚀 ~ file: recover-password.page.tsx ~ line 64 ~ onSettled ~ response: ', response);
        },
    }), mutate = _a.mutate, isLoading = _a.isLoading;
    var handleSubmit = function (formData) {
        var hasCpf = true;
        var hasFormError = Object.keys(formData.error).length > 0;
        if (formData.data.login.includes('@'))
            hasCpf = false;
        if (!hasFormError) {
            var requestData = {
                email: !hasCpf ? formData.data.login : undefined,
                cpf: hasCpf ? formData.data.login : undefined,
            };
            mutate(requestData);
        }
    };
    var COR_DA_SETA = 'white';
    return (React.createElement(TelaDeLogin, null,
        React.createElement(FormWrapper, null,
            React.createElement(ArrowLeftStyled, null,
                React.createElement(Tooltip, { title: "Voltar login", arrow: true },
                    React.createElement(Link, { to: AuthPath.Login },
                        React.createElement(FaIcon.ArrowLeft, { style: { color: COR_DA_SETA } })))),
            React.createElement(Separator, null),
            React.createElement(LogoWrapper, null,
                React.createElement(Logo, { src: company === null || company === void 0 ? void 0 : company.imgLogoEscuro })),
            React.createElement(ContainerStyled, null,
                React.createElement(ContentStyled, { top: true },
                    React.createElement(Titulo, null, Title))),
            React.createElement(Form, { onSubmit: handleSubmit },
                React.createElement("div", { className: "formularioInput" },
                    React.createElement(Form.Field, { name: LoginFormFields.Login, label: "LOGIN", validators: [Validators.Required(), Validators.LoginPatternRegex(Login.Error)] },
                        React.createElement(TextField, { type: "text", style: { borderRadius: 50 } })),
                    React.createElement(DivLink, null,
                        React.createElement(Link, { to: AppPath.ValidacaoDados }, " Recuperar senha usando dados pessoais "))),
                React.createElement(ButtonsWrapper, null,
                    React.createElement(Button, { type: "submit", loading: isLoading, variant: "primary", expanded: true }, ButtonText))))));
};
