var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import BlockIcon from '@mui/icons-material/Block';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { AppPath } from '@app/routes';
import { formatToPtBrDate, getColorByValue } from '@app/utils/shared-functions.utils';
import { BlockIconButton, LateLabel, LinkToDetails } from './checklist-card.styled';
import { BacksideContainerNew, CardStatus, CardStatusText, ChecklistCardContainer, FlexRowSimple, PrimaryCardText, SecondaryCardText, } from './styles';
export var CardNota = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: inline-block;\n  padding: 5px 5px;\n  border: 1.5px solid ", ";\n  border-radius: 10px; /* Rounded corners */\n  font-size: 12px;\n  font-weight: bold;\n  color: #49443c;\n  background-color: #fff;\n"], ["\n  display: inline-block;\n  padding: 5px 5px;\n  border: 1.5px solid ", ";\n  border-radius: 10px; /* Rounded corners */\n  font-size: 12px;\n  font-weight: bold;\n  color: #49443c;\n  background-color: #fff;\n"])), function (_a) {
    var value = _a.value;
    return getColorByValue(value);
});
var ColoredCard = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: inline-block;\n  padding: 5px 5px;\n  border: 1.5px solid ", ";\n  border-radius: 10px; /* Rounded corners */\n  font-size: 12px;\n  font-weight: bold;\n  color: #49443c;\n  background-color: #fff;\n"], ["\n  display: inline-block;\n  padding: 5px 5px;\n  border: 1.5px solid ", ";\n  border-radius: 10px; /* Rounded corners */\n  font-size: 12px;\n  font-weight: bold;\n  color: #49443c;\n  background-color: #fff;\n"])), function (_a) {
    var color = _a.color;
    return color;
});
export var CheckTypes;
(function (CheckTypes) {
    CheckTypes["TODAY_TYPE"] = "today";
    CheckTypes["UPCOMING_TYPE"] = "upcoming";
    CheckTypes["HISTORY_UNIT_TYPE"] = "history-unit";
    CheckTypes["HISTORY_COLABORATOR_TYPE"] = "history-colaborador";
    CheckTypes["PENDING_TODAY_COLAB_TYPE"] = "pending-today";
    CheckTypes["PENDING_TODAY_UNIT_TYPE"] = "pending-today-unit";
    CheckTypes["PENDING_NEXT3D_COLAB_TYPE"] = "pending-next3d";
    CheckTypes["PROGRESS_UNIT_TYPE"] = "progress-unit";
    CheckTypes["PENDING_APPROVAL_TYPE"] = "pending-approval";
    CheckTypes["DENIED_TYPE"] = "denied-approval";
})(CheckTypes || (CheckTypes = {}));
export var ChecklistCard = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
    var checklist = _a.checklist, type = _a.type, isFirst = _a.isFirst, isLast = _a.isLast, onClickImpossibilitar = _a.onClickImpossibilitar;
    var history = useHistory();
    var isPending = type.startsWith('pending');
    var isActionPlan = type.startsWith('pending-approval');
    var isHistory = type.startsWith('history');
    var isInProgress = type.startsWith('progress');
    var isDenied = type.startsWith('denied');
    var theme = useTheme();
    var getBorderColor = function () {
        return isInProgress ? '#f4c317' : '#d9d9d9';
    };
    var getBackgroundColor = function () {
        return isInProgress ? '#fffbee' : theme.backgroundColor;
    };
    var getCardLabel = function () {
        if (isInProgress)
            return 'Em andamento';
        if (isDenied)
            return 'Recusado';
        return 'Novo';
    };
    var navigateToPendingChecklist = function () {
        history.push(AppPath.PendingChecklist.replace(':id', checklist.id.toString()));
    };
    var navigateToChecklistDetails = function () {
        var _a, _b, _c;
        if (((_a = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _a === void 0 ? void 0 : _a.checkListUnidadePaiId) || ((_b = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _b === void 0 ? void 0 : _b.checklistUnidadePaiId)) {
            history.push({
                pathname: AppPath.ActionPlanChecklistDetails.replace(':id', checklist.id.toString()),
                state: { isHistory: isHistory },
            });
        }
        else {
            history.push({
                pathname: AppPath.ChecklistUnidadeDetails.replace(':id', checklist.id.toString()).replace(':uniEmpId', ((_c = checklist === null || checklist === void 0 ? void 0 : checklist.unidadeEmpresa) === null || _c === void 0 ? void 0 : _c.id) + ''),
                state: { isHistory: isHistory },
            });
        }
    };
    var navigateToPendingUnitChecklist = function (isProgress) {
        var _a, _b;
        if (isProgress)
            return history.push({
                pathname: AppPath.PendingUnitChecklist.replace(':id', checklist.id.toString()).replace(':uniEmpId', ((_a = checklist === null || checklist === void 0 ? void 0 : checklist.unidadeEmpresa) === null || _a === void 0 ? void 0 : _a.id) + ''),
                state: { isInProgress: true, checklistUnidadePendenteId: checklist.id },
            });
        var url = AppPath.PendingUnitChecklist.replace(':id', ((_b = checklist.checklistUnidade) === null || _b === void 0 ? void 0 : _b.id) + '');
        var url2 = url.replace(':uniEmpId', checklist.unidadeEmpresa.id + '');
        return history.push({
            pathname: url2,
            state: { checklistUnidadePendenteId: checklist.id },
        });
    };
    var navigateToUpcomingChecklist = function () {
        var url = AppPath.PendingUnitChecklist.replace(':id', (checklist === null || checklist === void 0 ? void 0 : checklist.id) + '');
        var url2 = url.replace(':uniEmpId', checklist.unidadeEmpresa.id + '');
        return history.push(url2);
    };
    var navigateToPendingApprovalUnitChecklist = function () {
        return history.push(AppPath.PendingApproval.replace(':id', checklist.id.toString()));
    };
    var navigateToDeniedChecklist = function () {
        return history.push(AppPath.DeniedChecklist.replace(':id', checklist.id.toString()));
    };
    var handleOnCardClick = function () {
        if (checklist.type === CheckTypes.PENDING_TODAY_UNIT_TYPE)
            navigateToPendingUnitChecklist();
        if (checklist.type === CheckTypes.PROGRESS_UNIT_TYPE)
            navigateToPendingUnitChecklist(true);
        if (checklist.type === CheckTypes.PENDING_APPROVAL_TYPE)
            navigateToPendingApprovalUnitChecklist();
        if (checklist.type === CheckTypes.DENIED_TYPE)
            navigateToDeniedChecklist();
        if (checklist.type === CheckTypes.UPCOMING_TYPE)
            navigateToUpcomingChecklist();
        if (checklist.type === CheckTypes.HISTORY_UNIT_TYPE)
            navigateToChecklistDetails();
        if (checklist.type === CheckTypes.PENDING_TODAY_COLAB_TYPE ||
            checklist.type === CheckTypes.PENDING_NEXT3D_COLAB_TYPE)
            navigateToPendingChecklist();
    };
    var isToday = function (dateToCheck) { return moment(dateToCheck).isSame(moment(), 'day'); };
    var isPast = function (dateToCheck) { return moment(dateToCheck).diff(moment(), 'day') < 0; };
    var getCardColor = function (c) {
        var VERDE = '#378805';
        var VERMELHO = '#F42E17';
        var AMARELO = '#F4C317';
        var TEMA = theme.corPrimaria;
        if (type === CheckTypes.PROGRESS_UNIT_TYPE)
            return AMARELO;
        if (type === CheckTypes.HISTORY_COLABORATOR_TYPE || type === CheckTypes.HISTORY_UNIT_TYPE)
            return TEMA;
        if (type === CheckTypes.PENDING_TODAY_COLAB_TYPE ||
            type === CheckTypes.PENDING_TODAY_UNIT_TYPE ||
            type === CheckTypes.PENDING_APPROVAL_TYPE) {
            return VERDE;
        }
        if (type === CheckTypes.DENIED_TYPE)
            return VERMELHO;
        return VERDE;
    };
    var nome = type === CheckTypes.HISTORY_COLABORATOR_TYPE
        ? checklist.nomeJogador
        : type === CheckTypes.HISTORY_UNIT_TYPE
            ? ((_b = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _b === void 0 ? void 0 : _b.unidadeEmpresa) ? (_d = (_c = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _c === void 0 ? void 0 : _c.unidadeEmpresa) === null || _d === void 0 ? void 0 : _d.nome : (_e = checklist === null || checklist === void 0 ? void 0 : checklist.unidadeEmpresa) === null || _e === void 0 ? void 0 : _e.nome
            : type === CheckTypes.PENDING_TODAY_UNIT_TYPE || type === CheckTypes.PROGRESS_UNIT_TYPE
                ? (_f = checklist === null || checklist === void 0 ? void 0 : checklist.unidadeEmpresa) === null || _f === void 0 ? void 0 : _f.nome : checklist.nomeJogador;
    var formatToPtBrDateTime = function (renderType) {
        var _a, _b;
        if (renderType === CheckTypes.HISTORY_UNIT_TYPE) {
            return moment(checklist === null || checklist === void 0 ? void 0 : checklist.dataEnvioChecklist).format('DD/MM/YYYY');
        }
        else if (renderType === CheckTypes.HISTORY_COLABORATOR_TYPE) {
            return moment(checklist === null || checklist === void 0 ? void 0 : checklist.dataCadastro).format('DD/MM/YYYY');
        }
        else if (renderType === CheckTypes.PENDING_TODAY_UNIT_TYPE) {
            return moment(checklist === null || checklist === void 0 ? void 0 : checklist.dataPrevista).format('DD/MM/YYYY');
        }
        else if (renderType === CheckTypes.PENDING_TODAY_COLAB_TYPE ||
            renderType === CheckTypes.PENDING_NEXT3D_COLAB_TYPE) {
            return moment((_a = checklist === null || checklist === void 0 ? void 0 : checklist.planoDeAcaoPai) === null || _a === void 0 ? void 0 : _a.dataLimiteResolucao).format('DD/MM/YYYY');
        }
        else if (renderType === CheckTypes.UPCOMING_TYPE) {
            return moment(checklist === null || checklist === void 0 ? void 0 : checklist.dataCadastro).format('DD/MM/YYYY');
        }
        else if (renderType === CheckTypes.PROGRESS_UNIT_TYPE) {
            return moment(checklist === null || checklist === void 0 ? void 0 : checklist.dataInicioResposta).format('DD/MM/YYYY');
        }
        else if (renderType === CheckTypes.PENDING_APPROVAL_TYPE) {
            return moment((_b = checklist === null || checklist === void 0 ? void 0 : checklist.planoAcao) === null || _b === void 0 ? void 0 : _b.dataLimiteResolucao).format('DD/MM/YYYY');
        }
        else {
            return moment(checklist === null || checklist === void 0 ? void 0 : checklist.dataEnvioChecklist).format('DD/MM/YYYY');
        }
    };
    var getChecklistLimite = function (renderType) {
        var _a, _b, _c, _d, _e, _f, _g;
        if (renderType === CheckTypes.HISTORY_UNIT_TYPE) {
            return checklist === null || checklist === void 0 ? void 0 : checklist.dataEnvioChecklist;
        }
        else if (renderType === CheckTypes.HISTORY_COLABORATOR_TYPE) {
            return checklist === null || checklist === void 0 ? void 0 : checklist.dataCadastro;
        }
        else if (renderType === CheckTypes.PENDING_APPROVAL_TYPE) {
            return (_a = checklist === null || checklist === void 0 ? void 0 : checklist.planoAcao) === null || _a === void 0 ? void 0 : _a.dataLimiteResolucao;
        }
        else if (renderType === CheckTypes.PENDING_TODAY_UNIT_TYPE) {
            return checklist === null || checklist === void 0 ? void 0 : checklist.dataPrevista;
        }
        else if (renderType === CheckTypes.PENDING_NEXT3D_COLAB_TYPE || renderType === CheckTypes.PROGRESS_UNIT_TYPE) {
            if ((_b = checklist === null || checklist === void 0 ? void 0 : checklist.planoDeAcaoPai) === null || _b === void 0 ? void 0 : _b.dataLimiteResolucao) {
                return (_c = checklist === null || checklist === void 0 ? void 0 : checklist.planoDeAcaoPai) === null || _c === void 0 ? void 0 : _c.dataLimiteResolucao;
            }
            else if ((_d = checklist === null || checklist === void 0 ? void 0 : checklist.planoAcao) === null || _d === void 0 ? void 0 : _d.dataLimiteResolucao) {
                return (_e = checklist === null || checklist === void 0 ? void 0 : checklist.planoAcao) === null || _e === void 0 ? void 0 : _e.dataLimiteResolucao;
            }
            else if (checklist === null || checklist === void 0 ? void 0 : checklist.dataInicioRecorrencia) {
                return checklist === null || checklist === void 0 ? void 0 : checklist.dataInicioRecorrencia;
            }
            else {
                return checklist === null || checklist === void 0 ? void 0 : checklist.dataCadastro;
            }
        }
        else if ((renderType === CheckTypes.UPCOMING_TYPE, renderType === CheckTypes.DENIED_TYPE)) {
            return ((_f = checklist === null || checklist === void 0 ? void 0 : checklist.planoDeAcaoPai) === null || _f === void 0 ? void 0 : _f.dataLimiteResolucao) || ((_g = checklist === null || checklist === void 0 ? void 0 : checklist.planoAcao) === null || _g === void 0 ? void 0 : _g.dataLimiteResolucao);
        }
        else {
            return checklist === null || checklist === void 0 ? void 0 : checklist.dataEnvioChecklist;
        }
    };
    var isUltimoDiaDoPrazo = isToday(getChecklistLimite(type));
    var isPrazoEstourado = isPast(getChecklistLimite(type));
    var showBlockButton = type !== CheckTypes.HISTORY_UNIT_TYPE &&
        type !== CheckTypes.HISTORY_UNIT_TYPE &&
        type !== CheckTypes.UPCOMING_TYPE &&
        type !== CheckTypes.PENDING_APPROVAL_TYPE;
    var getDataLimite = function (renderType) {
        if (renderType === CheckTypes.UPCOMING_TYPE) {
            return moment(getChecklistLimite(type)).add(1, 'day').format('DD/MM/YYYY');
        }
        return moment(getChecklistLimite(type)).format('DD/MM/YYYY - HH:mm');
    };
    return (React.createElement(BacksideContainerNew, { onClick: handleOnCardClick, borderColor: getBorderColor(), backgroundColor: getBackgroundColor(), style: {
            marginTop: isFirst ? '8px' : '0px',
            borderTopLeftRadius: isFirst ? '16px' : '0px',
            borderTopRightRadius: isFirst ? '16px' : '0px',
            borderBottomLeftRadius: isLast ? '16px' : '0px',
            borderBottomRightRadius: isLast ? '16px' : '0px',
        } },
        React.createElement(ChecklistCardContainer, { style: { marginBottom: '6px' } },
            React.createElement(React.Fragment, null,
                type === CheckTypes.PENDING_TODAY_UNIT_TYPE && (React.createElement(React.Fragment, null,
                    React.createElement(FlexRowSimple, { style: { columnGap: '8px', justifyContent: nome ? 'space-between' : 'end' } },
                        nome && React.createElement(PrimaryCardText, null, nome),
                        showBlockButton && (React.createElement(BlockIconButton, { onClick: function (e) { return onClickImpossibilitar(e, checklist); } },
                            React.createElement(BlockIcon, { sx: { fontSize: 32, color: 'black' } })))),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "CHECKLIST:"),
                        React.createElement(SecondaryCardText, null, (_g = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _g === void 0 ? void 0 : _g.titulo)),
                    (checklist === null || checklist === void 0 ? void 0 : checklist.nomeGestorResponsavel) && (React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "ORIGEM:"),
                        React.createElement(SecondaryCardText, null, checklist === null || checklist === void 0 ? void 0 : checklist.nomeGestorResponsavel))))),
                type === CheckTypes.PENDING_APPROVAL_TYPE && (React.createElement(React.Fragment, null,
                    React.createElement(FlexRowSimple, { style: { columnGap: '8px', justifyContent: nome ? 'space-between' : 'end' } },
                        nome && React.createElement(PrimaryCardText, null, nome),
                        showBlockButton && (React.createElement(BlockIconButton, { onClick: function (e) { return onClickImpossibilitar(e, checklist); } },
                            React.createElement(BlockIcon, { sx: { fontSize: 32, color: 'black' } })))),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "CHECKLIST:"),
                        React.createElement(SecondaryCardText, null, checklist === null || checklist === void 0 ? void 0 : checklist.titulo)),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "GESTOR ORIGEM:"),
                        React.createElement(SecondaryCardText, null, (_j = (_h = checklist === null || checklist === void 0 ? void 0 : checklist.planoAcao) === null || _h === void 0 ? void 0 : _h.gestor) === null || _j === void 0 ? void 0 : _j.nome)))),
                type === CheckTypes.DENIED_TYPE && (React.createElement(React.Fragment, null,
                    React.createElement(FlexRowSimple, { style: { columnGap: '8px', justifyContent: nome ? 'space-between' : 'end' } },
                        nome && React.createElement(PrimaryCardText, null, nome),
                        showBlockButton && (React.createElement(BlockIconButton, { onClick: function (e) { return onClickImpossibilitar(e, checklist); } },
                            React.createElement(BlockIcon, { sx: { fontSize: 32, color: 'black' } })))),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "CHECKLIST:"),
                        React.createElement(SecondaryCardText, null, (_k = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _k === void 0 ? void 0 : _k.titulo)),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "GESTOR ORIGEM:"),
                        React.createElement(SecondaryCardText, null, (_l = checklist === null || checklist === void 0 ? void 0 : checklist.gestorResponsavel) === null || _l === void 0 ? void 0 : _l.nome)))),
                type === CheckTypes.PROGRESS_UNIT_TYPE && (React.createElement(React.Fragment, null,
                    React.createElement(FlexRowSimple, { style: { columnGap: '8px', justifyContent: nome ? 'space-between' : 'end' } },
                        nome && React.createElement(PrimaryCardText, null, nome),
                        showBlockButton && (React.createElement(BlockIconButton, { onClick: function (e) { return onClickImpossibilitar(e, checklist); } },
                            React.createElement(BlockIcon, { sx: { fontSize: 32, color: 'black' } })))),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "CHECKLIST:"),
                        React.createElement(SecondaryCardText, null, (_m = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _m === void 0 ? void 0 : _m.titulo)),
                    (checklist === null || checklist === void 0 ? void 0 : checklist.gestorResponsavel) && (React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "ORIGEM:"),
                        React.createElement(SecondaryCardText, null, (_o = checklist === null || checklist === void 0 ? void 0 : checklist.gestorResponsavel) === null || _o === void 0 ? void 0 : _o.nome))))),
                type === CheckTypes.UPCOMING_TYPE && (React.createElement(React.Fragment, null,
                    React.createElement(FlexRowSimple, { style: { columnGap: '8px', justifyContent: nome ? 'space-between' : 'end' } },
                        nome && React.createElement(PrimaryCardText, null, nome),
                        showBlockButton && (React.createElement(BlockIconButton, { onClick: function (e) { return onClickImpossibilitar(e, checklist); } },
                            React.createElement(BlockIcon, { sx: { fontSize: 32, color: 'black' } })))),
                    (checklist === null || checklist === void 0 ? void 0 : checklist.unidadeEmpresa) && (React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "UNIDADE:"),
                        React.createElement(SecondaryCardText, null, (_p = checklist === null || checklist === void 0 ? void 0 : checklist.unidadeEmpresa) === null || _p === void 0 ? void 0 : _p.nome))),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "CHECKLIST:"),
                        React.createElement(SecondaryCardText, null, checklist === null || checklist === void 0 ? void 0 : checklist.titulo)))),
                type === CheckTypes.HISTORY_UNIT_TYPE && (React.createElement(React.Fragment, null,
                    React.createElement(FlexRowSimple, { style: { columnGap: '8px', justifyContent: nome ? 'space-between' : 'end' } },
                        nome && React.createElement(PrimaryCardText, null, nome),
                        (checklist === null || checklist === void 0 ? void 0 : checklist.nota) >= 0 && React.createElement(CardNota, { value: (_q = checklist === null || checklist === void 0 ? void 0 : checklist.nota) !== null && _q !== void 0 ? _q : 0 }, checklist === null || checklist === void 0 ? void 0 :
                            checklist.nota,
                            "%")),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, (_r = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _r === void 0 ? void 0 : _r.titulo)),
                    ((_s = checklist === null || checklist === void 0 ? void 0 : checklist.checklistUnidade) === null || _s === void 0 ? void 0 : _s.planoAcao) && (React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "Checklist: Plano de A\u00E7\u00E3o"))),
                    ((_t = checklist === null || checklist === void 0 ? void 0 : checklist.gestorResponsavel) === null || _t === void 0 ? void 0 : _t.nome) && (React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null,
                            "Respons\u00E1vel: ", (_u = checklist === null || checklist === void 0 ? void 0 : checklist.gestorResponsavel) === null || _u === void 0 ? void 0 :
                            _u.nome))),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, getDataLimite(type))),
                    (checklist === null || checklist === void 0 ? void 0 : checklist.nomeGestorResponsavel) && (React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "ORIGEM:"),
                        React.createElement(SecondaryCardText, null, checklist === null || checklist === void 0 ? void 0 : checklist.nomeGestorResponsavel))))),
                type === CheckTypes.HISTORY_COLABORATOR_TYPE && (React.createElement(React.Fragment, null,
                    React.createElement(FlexRowSimple, { style: { columnGap: '8px', justifyContent: nome ? 'space-between' : 'end' } },
                        nome && React.createElement(PrimaryCardText, null, nome),
                        showBlockButton && (React.createElement(BlockIconButton, { onClick: function (e) { return onClickImpossibilitar(e, checklist); } },
                            React.createElement(BlockIcon, { sx: { fontSize: 32, color: 'black' } })))),
                    React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "DESCRI\u00C7\u00C3O:"),
                        React.createElement(SecondaryCardText, null, checklist === null || checklist === void 0 ? void 0 : checklist.descricao)),
                    (checklist === null || checklist === void 0 ? void 0 : checklist.nomeGestorResponsavel) && (React.createElement(FlexRowSimple, null,
                        React.createElement(PrimaryCardText, null, "ORIGEM:"),
                        ' ',
                        React.createElement(SecondaryCardText, null, checklist === null || checklist === void 0 ? void 0 : checklist.nomeGestorResponsavel))),
                    React.createElement("p", null,
                        React.createElement("b", null,
                            isPending ? 'APROV. ANTERIOR' : 'APROVEITAMENTO',
                            ":"),
                        " ",
                        checklist.percentualAproveitamento,
                        "%",
                        '',
                        isPending && React.createElement("span", null,
                            " (em ",
                            formatToPtBrDate(checklist === null || checklist === void 0 ? void 0 : checklist.dataCadastro),
                            ")")))),
                (type === CheckTypes.HISTORY_COLABORATOR_TYPE || type === CheckTypes.HISTORY_UNIT_TYPE) && (React.createElement(LinkToDetails, { to: (type === CheckTypes.HISTORY_UNIT_TYPE
                        ? AppPath.ChecklistUnidadeDetails
                        : AppPath.ChecklistDetails).replace(':id', "" + checklist.id) }, "Ver Respostas"))),
            isActionPlan && (React.createElement(FlexRowSimple, { style: { columnGap: '8px' } },
                React.createElement(PrimaryCardText, null, "Dispon\u00EDvel Em:"),
                React.createElement(SecondaryCardText, null, formatToPtBrDateTime(type)))),
            React.createElement(FlexRowSimple, { style: { columnGap: '8px' } },
                React.createElement(PrimaryCardText, null, isHistory ? '' : 'Prazo Limite:'),
                React.createElement(SecondaryCardText, null, isHistory ? '' : getDataLimite(type)),
                type !== CheckTypes.UPCOMING_TYPE && !isHistory && isPrazoEstourado && (React.createElement(LateLabel, null,
                    React.createElement("span", null, "Atrasado"))))),
        !isHistory && (React.createElement(CardStatus, { cardColor: getCardColor(checklist) },
            React.createElement(CardStatusText, null, getCardLabel()))),
        isActionPlan && (React.createElement(CardStatus, { cardColor: '#49443C', style: { borderTopRightRadius: '0px', borderTopLeftRadius: '0px' } },
            React.createElement(CardStatusText, null, "Plano de A\u00E7\u00E3o")))));
};
var templateObject_1, templateObject_2;
