/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { Separator } from '@atomic';
import { Button } from '@atomic/atm.button';
import { InputDate } from '@atomic/atom.Input-date/input-date-component.style';
import { Row, Col } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ButtonsRow, Label, Subtitle } from './date-range-modal.styled';
export var DateRangeModal = function (_a) {
    var isOpened = _a.isOpened, handleOnClose = _a.handleOnClose, startDateSelected = _a.startDateSelected, endDateSelected = _a.endDateSelected;
    var _b = React.useState(startDateSelected), startDate = _b[0], setStartDate = _b[1];
    var _c = React.useState(endDateSelected), endDate = _c[0], setEndDate = _c[1];
    var handleOnChangeStartDateDate = function (event) {
        setStartDate(event.target.value);
    };
    var handleOnChangeEndDateDate = function (event) {
        setEndDate(event.target.value);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { opened: isOpened, closeOnOutsideClick: false, onClose: function () { return handleOnClose(startDate, endDate); } },
            React.createElement(Subtitle, null, "Per\u00EDodo de Busca"),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 6, style: { marginBottom: '0.5rem' } },
                    React.createElement(Label, null, "Data inicial")),
                React.createElement(Col, { xs: 6, style: { marginBottom: '0.5rem' } },
                    React.createElement(Label, null, "Data final"))),
            React.createElement(Row, null,
                React.createElement(Col, { xs: 6 },
                    React.createElement(InputDate, { defaultValue: startDate, type: "date", onChange: handleOnChangeStartDateDate, required: true })),
                React.createElement(Col, { xs: 6 },
                    React.createElement(InputDate, { defaultValue: endDate, type: "date", onChange: handleOnChangeEndDateDate, required: true }))),
            React.createElement(Separator, null),
            React.createElement(ButtonsRow, null,
                React.createElement(Col, null,
                    React.createElement(Button, { onClick: function () { return handleOnClose(startDate, endDate); } }, "Aplicar"))))));
};
