var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import { useStore } from 'react-context-hook';
import { useQuery } from 'react-query';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { axiosClient } from '@app/configure-app';
import { Separator } from '@atomic';
import { Button } from '@atomic/atm.button';
import { Row, Col } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ButtonsRow, Label, Subtitle } from './checklist-filter-modal.styled';
import { ChecklistUnidadeFilterType } from './interfaces';
var CHECKLIST_UNIDADE_TYPES_FILTER = [
    { value: 1, label: 'Recorrente', type: ChecklistUnidadeFilterType.CHECKLIST_UNIDADE_RECORRENTE },
    { value: 2, label: 'Dupla Validação', type: ChecklistUnidadeFilterType.CHECKLIST_UNIDADE_RECUSADO },
    { value: 3, label: 'Plano de Ação', type: ChecklistUnidadeFilterType.CHECKLIST_UNIDADE_PLANO_ACAO },
];
export var ChecklistFilterModal = function (_a) {
    var isOpened = _a.isOpened, handleOnClose = _a.handleOnClose, unidadesSelected = _a.unidadesSelected, checklistTypesSelected = _a.checklistTypesSelected, gestorSelected = _a.gestorSelected;
    var company = useStore('company')[0];
    var _b = React.useState(unidadesSelected), unidades = _b[0], setUnidades = _b[1];
    var _c = React.useState(checklistTypesSelected), checklistUnidadeTypesFilter = _c[0], setChecklistUnidadeTypesFilter = _c[1];
    var _d = React.useState(gestorSelected), gestorFilter = _d[0], setGestorFilter = _d[1];
    var animatedComponents = makeAnimated();
    var handleOnUnidadeChange = function (value) {
        setUnidades(value);
    };
    var handleOnTypeChange = function (value) {
        setChecklistUnidadeTypesFilter(value);
    };
    var handleOnGestorChange = function (value) {
        setGestorFilter(value);
    };
    var GET_COMPANY_UNITS_URL = "unidade-empresa/por-usuario";
    var companyUnits = useQuery(['Get-unidades-por-usuario'], function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_COMPANY_UNITS_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data.map(function (item) {
                            return {
                                label: item.nome,
                                value: item.id,
                            };
                        })];
                case 2:
                    error_1 = _a.sent();
                    console.log(error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var GET_USUARIOS_URL = "usuario/unidades-compartilhadas";
    var usuarios = useQuery(['get-usuarios-unidades-compartilhadas'], function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(GET_USUARIOS_URL)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data.data.map(function (item) {
                            return {
                                label: item.nome,
                                value: item.id,
                            };
                        })];
                case 2:
                    error_2 = _a.sent();
                    console.log(error_2);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { opened: isOpened, closeOnOutsideClick: false, onClose: function () { return handleOnClose(unidades, checklistUnidadeTypesFilter, gestorFilter); } },
            React.createElement(Subtitle, null, "Par\u00E2metros de Busca"),
            React.createElement(Row, { style: { marginBottom: '0.5rem' } },
                React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                    React.createElement(Label, null, "Escolha a Unidade:")),
                React.createElement(Col, { xs: 12 },
                    React.createElement(Select, { value: unidades, onChange: handleOnUnidadeChange, options: companyUnits, components: animatedComponents, isMulti: true }))),
            React.createElement(Row, { style: { marginBottom: '0.5rem' } },
                React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                    React.createElement(Label, null, "Escolha o Tipo de Checklist:")),
                React.createElement(Col, { xs: 12 },
                    React.createElement(Select, { value: checklistUnidadeTypesFilter, onChange: handleOnTypeChange, options: CHECKLIST_UNIDADE_TYPES_FILTER, components: animatedComponents, isMulti: true }))),
            React.createElement(Row, { style: { marginBottom: '0.5rem' } },
                React.createElement(Col, { xs: 12, style: { marginBottom: '0.5rem' } },
                    React.createElement(Label, null, "Escolha o Gestor:")),
                React.createElement(Col, { xs: 12 },
                    React.createElement(Select, { value: gestorFilter, onChange: handleOnGestorChange, options: usuarios, components: animatedComponents, isMulti: true }))),
            React.createElement(Separator, null),
            React.createElement(ButtonsRow, null,
                React.createElement(Col, null,
                    React.createElement(Button, { onClick: function () { return handleOnClose(unidades, checklistUnidadeTypesFilter, gestorFilter); } }, "Aplicar"))))));
};
