var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import React, { useEffect, useState } from 'react';
import { AiFillDelete } from 'react-icons/ai';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useMutation } from 'react-query';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { IconColor, PreviewLabel } from '@app/modules/questionnarie/questionnaire.styled';
import { compressFileImage } from '@app/utils/image-compressor/image-compressor';
import { FlashMessageEnum, StyledContainer } from '@atomic';
import { Button } from '@atomic/atm.button';
import { Col, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ButtonsRow, Title, Subtitle } from './modal-foto.styled';
export var ModalFoto = function (_a) {
    var _b = _a.isChecklistUnidade, isChecklistUnidade = _b === void 0 ? false : _b, isOpened = _a.isOpened, handleOnClose = _a.handleOnClose, onSend = _a.onSend, 
    // respostaGestorId,
    checklist = _a.checklist, currentRespostaItemId = _a.currentRespostaItemId, setFotosPreview = _a.setFotosPreview, itemFotos = _a.itemFotos, _c = _a.isPermitidoImagemDaBiblioteca, isPermitidoImagemDaBiblioteca = _c === void 0 ? true : _c;
    var showFlashMessage = useFlashMessage()[0];
    var POST_FOTO = 'checklist/item/resposta/upload-foto';
    var POST_FOTO_UNIDADE = "checklistunidade/item/resposta/" + currentRespostaItemId + "/upload-foto";
    var _d = useState(''), base64Image = _d[0], setBase64Image = _d[1];
    var _e = useState([]), images = _e[0], setImages = _e[1];
    useEffect(function () {
        var _a;
        if (itemFotos && (itemFotos === null || itemFotos === void 0 ? void 0 : itemFotos.fotoUrlList) && ((_a = itemFotos === null || itemFotos === void 0 ? void 0 : itemFotos.fotoUrlList) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            initImages();
        }
    }, [itemFotos]);
    var initImages = function () { return __awaiter(void 0, void 0, void 0, function () {
        var files, idx, _i, _a, url, foto;
        return __generator(this, function (_b) {
            files = [];
            idx = 1;
            for (_i = 0, _a = itemFotos === null || itemFotos === void 0 ? void 0 : itemFotos.fotoUrlList; _i < _a.length; _i++) {
                url = _a[_i];
                foto = {
                    name: "Imagem " + idx,
                    data: url,
                    url: true,
                };
                files.push(foto);
                idx++;
            }
            setImages(files);
            return [2 /*return*/];
        });
    }); };
    // const changeChecklistPreenchidoResponse = () => {
    //   const responseChecklist = checklistPreenchidoResponse.respostas;
    //   const newResponse = responseChecklist.map((e: any) => {
    //     const checklistUnidadeRespostaItemId = e.id;
    //     delete e.id;
    //     e.checklistUnidadeRespostaItemId = checklistUnidadeRespostaItemId;
    //     return e;
    //   });
    //   return newResponse;
    // };
    var _f = useMutation(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, fotoList, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    fotoList = images.map(function (foto) { return foto.data; });
                    if (isChecklistUnidade) {
                        formattedData = {
                            ChecklistRespostaItemId: currentRespostaItemId,
                            // ChecklistPreenchido: changeChecklistPreenchidoResponse(),
                            ArquivosFotoBase64: fotoList,
                        };
                    }
                    else {
                        formattedData = {
                            // RespostaGestorId: respostaGestorId,
                            ChecklistRespostaItemId: checklist.id,
                            ChecklistPreenchido: currentRespostaItemId,
                            ArquivosFotoBase64: fotoList,
                        };
                    }
                    return [4 /*yield*/, axiosClient.post(isChecklistUnidade ? POST_FOTO_UNIDADE : POST_FOTO, formattedData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function (data) {
            var _a, _b, _c;
            if (setFotosPreview) {
                setFotosPreview({
                    id: (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.checklistUnidadeItemId,
                    respostaItemId: (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.id,
                    fotoUrlList: (_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.urlFotoRespostaList,
                });
            }
            showFlashMessage(FlashMessageEnum.success, 'Foto enviada com sucesso.');
            onSend();
            internalOnClose();
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao salvar o Foto');
        },
    }), mutate = _f.mutate, isLoading = _f.isLoading;
    var handleOnSubmit = function (event) {
        event.preventDefault();
        mutate();
    };
    var internalOnClose = function () {
        setImages([]);
        setBase64Image('');
        handleOnClose();
    };
    var hiddenFileInput = React.useRef(null);
    var handleClick = function () {
        hiddenFileInput.current.click();
    };
    var processImageInput = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var files, _i, _a, file, base64, foto;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    files = __spreadArrays(images);
                    _i = 0, _a = e.target.files;
                    _b.label = 1;
                case 1:
                    if (!(_i < _a.length)) return [3 /*break*/, 4];
                    file = _a[_i];
                    return [4 /*yield*/, compressFileImage(file)];
                case 2:
                    base64 = _b.sent();
                    foto = {
                        name: file.name,
                        data: base64,
                        url: false,
                    };
                    files.push(foto);
                    _b.label = 3;
                case 3:
                    _i++;
                    return [3 /*break*/, 1];
                case 4:
                    setImages(files);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleDeleteImageClick = function (index) {
        var files = images.filter(function (item, idx) { return idx != index; });
        setImages(files);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { opened: isOpened, closeOnOutsideClick: false, onClose: internalOnClose },
            React.createElement(Title, null,
                React.createElement(FaExclamationTriangle, null)),
            React.createElement(Subtitle, null, "Foto"),
            React.createElement("form", { onSubmit: handleOnSubmit },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 },
                        React.createElement(Button, { variant: "link", outlined: true, expanded: true, onClick: handleClick, style: { height: base64Image != '' ? 'inherit' : '6rem' } }, images.length > 0 ? 'Clique aqui enviar mais imagens' : 'Clique aqui e envie sua imagem-resposta'),
                        React.createElement("input", { type: "file", ref: hiddenFileInput, onChange: processImageInput, style: { display: 'none' }, accept: "image/*", capture: isPermitidoImagemDaBiblioteca ? undefined : 'camera', multiple: true })),
                    images.length > 0 && (React.createElement(Col, { xs: 12 },
                        React.createElement(StyledContainer, { justifyCenter: true, alignCenter: true },
                            React.createElement(PreviewLabel, { themeFontColor: true }, "Imagens escolhidas")))),
                    images.map(function (image, idx) { return (React.createElement("div", { key: idx, style: {
                            display: 'inline-flex',
                            alignItems: 'start',
                            justifyContent: 'flex-start',
                            columnGap: '4px',
                            width: '100%',
                        } },
                        React.createElement(Col, { xs: 9 },
                            React.createElement("div", { style: {
                                    display: 'inline-flex',
                                    alignItems: 'start',
                                    justifyContent: 'flex-start',
                                    columnGap: '4px',
                                    width: '95%',
                                } },
                                React.createElement("div", { style: { textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' } },
                                    React.createElement("span", null,
                                        React.createElement("b", { style: { fontSize: '14px' } },
                                            idx + 1,
                                            " - ",
                                            image.name))))),
                        React.createElement(Col, { xs: 3 },
                            React.createElement(StyledContainer, { justifyCenter: true },
                                React.createElement("img", { src: image.data, alt: "", height: "20px", width: "20px" }),
                                React.createElement(IconColor, { style: { padding: '1px', marginLeft: '5px' }, onClick: function () { return handleDeleteImageClick(idx); } },
                                    React.createElement(AiFillDelete, { style: { width: '20px', height: '20px' } })))))); })),
                React.createElement(ButtonsRow, null,
                    React.createElement(Col, null,
                        React.createElement(Button, { type: "submit", loading: isLoading }, "Salvar Imagens")))))));
};
