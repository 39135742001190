var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState } from 'react';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useMutation } from 'react-query';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { PreviewImage, PreviewLabel } from '@app/modules/questionnarie/questionnaire.styled';
import { compressFileImage } from '@app/utils/image-compressor/image-compressor';
import { FlashMessageEnum, StyledContainer } from '@atomic';
import { Button } from '@atomic/atm.button';
import { Col, Row } from '@atomic/obj.grid';
import { Modal } from '@atomic/obj.modal';
import { ButtonsRow, Title, Subtitle } from './modal-foto.styled';
export var ModalFotoNew = function (_a) {
    var _b = _a.isChecklistUnidade, isChecklistUnidade = _b === void 0 ? false : _b, isOpened = _a.isOpened, handleOnClose = _a.handleOnClose, onSend = _a.onSend, currentRespostaItemId = _a.currentRespostaItemId;
    var showFlashMessage = useFlashMessage()[0];
    var POST_FOTO = 'checklist/item/resposta/upload-foto';
    var POST_FOTO_UNIDADE = "checklistunidade/item/resposta/" + currentRespostaItemId + "/upload-foto";
    var _c = useState(''), base64Image = _c[0], setBase64Image = _c[1];
    var _d = useMutation(function () { return __awaiter(void 0, void 0, void 0, function () {
        var formattedData, response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    formattedData = {
                        checklistRespostaItemId: currentRespostaItemId,
                        arquivoFotoBase64: base64Image,
                    };
                    return [4 /*yield*/, axiosClient.post(isChecklistUnidade ? POST_FOTO_UNIDADE : POST_FOTO, formattedData)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
            }
        });
    }); }, {
        onSuccess: function () {
            showFlashMessage(FlashMessageEnum.success, 'Foto enviada com sucesso.');
            onSend();
            internalOnClose();
        },
        onError: function () {
            showFlashMessage(FlashMessageEnum.alert, 'Erro ao salvar o Foto');
        },
    }), mutate = _d.mutate, isLoading = _d.isLoading;
    var handleOnSubmit = function (event) {
        event.preventDefault();
        mutate();
    };
    var internalOnClose = function () {
        setBase64Image('');
        handleOnClose();
    };
    var hiddenFileInput = React.useRef(null);
    var handleClick = function () {
        hiddenFileInput.current.click();
    };
    var processImageInput = function (e) { return __awaiter(void 0, void 0, void 0, function () {
        var file, base64;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    file = e.target.files[0];
                    return [4 /*yield*/, compressFileImage(file)];
                case 1:
                    base64 = _a.sent();
                    setBase64Image(base64);
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement(React.Fragment, null,
        React.createElement(Modal, { opened: isOpened, closeOnOutsideClick: false, onClose: internalOnClose },
            React.createElement(Title, null,
                React.createElement(FaExclamationTriangle, null)),
            React.createElement(Subtitle, null, "Foto"),
            React.createElement("form", { onSubmit: handleOnSubmit },
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 12 },
                        React.createElement(Button, { variant: "link", outlined: true, expanded: true, onClick: handleClick, style: { height: base64Image != '' ? 'inherit' : '6rem' } }, base64Image != '' ? 'Clique aqui para trocar' : 'Clique aqui e envie sua imagem-resposta'),
                        React.createElement("input", { type: "file", ref: hiddenFileInput, onChange: processImageInput, style: { display: 'none' } })),
                    React.createElement(Col, { xs: 3 }, base64Image && (React.createElement(StyledContainer, { justifyCenter: true, alignCenter: true, style: { height: '100%' } },
                        React.createElement(PreviewLabel, { themeFontColor: true }, "Imagem escolhida:")))),
                    React.createElement(PreviewImage, { xs: 9 },
                        React.createElement(StyledContainer, { justifyCenter: true },
                            React.createElement("img", { src: base64Image, alt: "", height: "100%" })))),
                React.createElement(ButtonsRow, null,
                    React.createElement(Col, null,
                        React.createElement(Button, { type: "submit", loading: isLoading, disabled: base64Image === '' }, "Enviar")))))));
};
