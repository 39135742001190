var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var MapContainer = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: fit-content;\n  overflow-y: hidden;\n  padding-bottom: 14vh;\n  border-radius: 25px 25px 0 0;\n  justify-content: center;\n\n  ", ";\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: fit-content;\n  overflow-y: hidden;\n  padding-bottom: 14vh;\n  border-radius: 25px 25px 0 0;\n  justify-content: center;\n\n  ",
    ";\n"])), function (props) {
    return props.gridMode && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      flex-wrap: wrap;\n      justify-content: flex-start;\n      flex-direction: row;\n      margin: 0;\n    "], ["\n      flex-wrap: wrap;\n      justify-content: flex-start;\n      flex-direction: row;\n      margin: 0;\n    "])));
});
export var StyledNotFound = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: white;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  padding-top: 200px;\n"], ["\n  background-color: white;\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  text-align: center;\n  padding-top: 200px;\n"])));
export var HomeOptions = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  height: 50px;\n  display: flex;\n  flex-direction: row-reverse;\n  padding-right: 10px;\n  padding-top: 5px;\n  background-color: ", ";\n  line-height: 0;\n  /* bug device */\n  margin-bottom: -1px;\n"], ["\n  height: 50px;\n  display: flex;\n  flex-direction: row-reverse;\n  padding-right: 10px;\n  padding-top: 5px;\n  background-color: ", ";\n  line-height: 0;\n  /* bug device */\n  margin-bottom: -1px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
export var HomeOption = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-radius: 50%;\n  background-color: #ff9d56;\n  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);\n  width: 35px;\n  height: 35px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-left: 5px;\n\n  &:hover {\n    cursor: pointer;\n  }\n"], ["\n  border-radius: 50%;\n  background-color: #ff9d56;\n  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);\n  width: 35px;\n  height: 35px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-left: 5px;\n\n  &:hover {\n    cursor: pointer;\n  }\n"])));
export var NotificationCircle = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  width: 10px;\n  height: 10px;\n  background: ", ";\n  border-radius: 50%;\n  position: absolute;\n  border: 1px solid #ff9d56;\n  top: 0;\n  right: 0;\n"], ["\n  width: 10px;\n  height: 10px;\n  background: ", ";\n  border-radius: 50%;\n  position: absolute;\n  border: 1px solid #ff9d56;\n  top: 0;\n  right: 0;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.corPrimaria;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
