var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { Header } from '@app/components/mol.header';
import { useFlashMessage } from '@app/components/obj.flash-message';
import { axiosClient } from '@app/configure-app';
import { AppPath } from '@app/routes';
import { StyledContainer } from '@atomic';
import { FlashMessageEnum } from '@atomic';
import { ChecklistNew } from './components/checklist/checklist-new.component';
export var NewChecklistPage = function (props) {
    var _a, _b, _c;
    var idJogador = (_a = props.location.state) === null || _a === void 0 ? void 0 : _a.idJogador;
    var idCurso = (_b = props.location.state) === null || _b === void 0 ? void 0 : _b.idCurso;
    var nomeJogador = (_c = props.location.state) === null || _c === void 0 ? void 0 : _c.nomeJogador;
    // const checklistItems = props.location.state?.checklistItems;
    var showFlashMessage = useFlashMessage()[0];
    var history = useHistory();
    var getChecklistUrl = function () { return "checklist/jogador/" + idJogador + "/curso/" + idCurso; };
    var checklistResponse = useQuery("getChecklistItems-" + idJogador + "-" + idCurso, function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, axiosClient.get(getChecklistUrl())];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response.data];
                case 2:
                    error_1 = _a.sent();
                    showFlashMessage(FlashMessageEnum.alert, (error_1 === null || error_1 === void 0 ? void 0 : error_1.stack) || error_1);
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); }).data;
    var handleOnBackButtonClick = function () {
        history.push({ pathname: AppPath.RoutineChecklist });
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(StyledContainer, { primaryColor: true, className: "use-inverse-colors" },
            React.createElement(Header, { withBackButton: true, title: "Checagem", backButtonFn: handleOnBackButtonClick },
                React.createElement(StyledContainer, { withPadding: true },
                    React.createElement("div", null,
                        "Jogador: ",
                        React.createElement("b", null, nomeJogador))))),
        React.createElement(StyledContainer, { withPadding: true },
            React.createElement(ChecklistNew, { checklistItems: checklistResponse === null || checklistResponse === void 0 ? void 0 : checklistResponse.data, jogadorId: idJogador }))));
};
